import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { Training }         from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canFilter  : false,
    canImport  : false,
    canExport  : false,
    list       : [],
    total      : 0,
    elem       : {},
    whiteLists : [],
    statuses   : [],
    sort       : {
        orderBy  : "fromTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "TRAINING_LOADING" });
    },

    /**
     * Fetches the Training List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "ACTIVITY") {
            params.activityID = elemID;
        }

        const data = await Training.getAll(params);
        data.sort  = params;
        dispatch({ type : "TRAINING_LIST", data });
    },

    /**
     * Fetches a single Training
     * @param {Function} dispatch
     * @param {Number}   trainingID
     * @returns {Promise}
     */
    async fetchElem(dispatch, trainingID) {
        const data = await Training.getOne({ trainingID });
        dispatch({ type : "TRAINING_ELEM", data });
    },

    /**
     * Fetches the Training Edit data
     * @param {Function} dispatch
     * @param {Number}   activityID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, activityID) {
        const data = await Training.getEditData({ activityID });
        dispatch({ type : "TRAINING_EDIT", data });
    },

    /**
     * Edits/Creates a Training
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return Training.edit(data);
    },

    /**
     * Deletes a Training
     * @param {Function} dispatch
     * @param {Number}   trainingID
     * @returns {Promise}
     */
    deleteElem(dispatch, trainingID) {
        return Training.delete({ trainingID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.fromDate         = DateTime.formatDate(elem.fromTime, "dashesReverse");
    elem.fromTimeDate     = DateTime.formatDate(elem.fromTime, "dashes");
    elem.toDate           = DateTime.formatDate(elem.toTime, "dashesReverse");
    elem.toTimeDate       = DateTime.formatDate(elem.toTime, "dashes");
    elem.subscriptionText = `${elem.confirmedSubscriptions} / ${elem.totalSubscriptions}`;
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "TRAINING_LIST", "TRAINING_ELEM", "TRAINING_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "TRAINING_LOADING":
        return {
            ...state,
            loading    : true,
        };

    case "TRAINING_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canFilter  : action.data.canFilter,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            list       : Utils.parseList(action.data.list, parseElem),
            total      : action.data.total,
            statuses   : action.data.statuses,
            sort       : action.data.sort,
        };

    case "TRAINING_ELEM":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            elem       : parseElem(action.data.elem),
            whiteLists : action.data.whiteLists,
            statuses   : action.data.statuses,
        };

    case "TRAINING_EDIT":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            whiteLists : action.data.whiteLists,
            statuses   : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
