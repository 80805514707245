import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import BarIcon              from "Dashboard/Components/Core/BarIcon";



/**
 * The Support Admin Menu
 * @returns {React.ReactElement}
 */
function SupportAdminMenu() {
    const { isSupportAdmin, credential } = Store.useState("auth");
    const { closeMenu } = Store.useAction("core");


    // Do the Render
    if (!isSupportAdmin) {
        return <React.Fragment />;
    }
    return <>
        <BarIcon
            url="ACTIVITY"
            icon="activity"
            message={credential.activityName || "ACTIVITIES_NAME"}
            onClick={closeMenu}
            tooltipDelay={0.2}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="TICKETS"
            icon="ticket"
            message="TICKETS_NAME"
            tooltipDelay={0.2}
            withText
            withTooltip
            startsWith
        />
    </>;
}

export default SupportAdminMenu;
