import React                from "react";
import PropTypes            from "prop-types";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Commons              from "Utils/Commons";

// Components
import ShiftContent         from "./Shift/ShiftContent";
import CourseContent        from "./Course/CourseContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Shift Container
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ShiftContainer(props) {
    const { type } = props;

    const { shiftTab } = Navigate.useParams();
    const navigate = Commons.useActivityGoto();


    // The Current State
    const [ isCreating, setCreating ] = React.useState(false);


    // Handle the Tab
    React.useEffect(() => {
        if (!shiftTab) {
            navigate("SHIFT_PAGE", "SHIFTS");
        }
    }, [ shiftTab ]);

    // Handles the Tab
    const handleTab = (url) => {
        navigate("SHIFT_PAGE", url);
    };


    // Variables
    const isShifts  = Navigate.isUrl("SHIFTS", shiftTab);
    const isCourses = Navigate.isUrl("COURSES", shiftTab);


    // Do the Render
    return <Main>
        <Header message="SHIFTS_NAME" icon="shift">
            <ActionList>
                <ActionItem
                    variant="outlined"
                    icon="add"
                    message="GENERAL_CREATE"
                    onClick={() => setCreating(true)}
                />
            </ActionList>
        </Header>

        <Content className="light-scrollbars">
            <TabList
                selected={shiftTab || NLS.url("SHIFTS")}
                onClick={handleTab}
            >
                <TabItem
                    message="SHIFTS_NAME"
                    url="SHIFTS"
                />
                <TabItem
                    message="COURSES_NAME"
                    url="COURSES"
                />
            </TabList>

            {isShifts && <ShiftContent
                type={type}
                isCreating={isCreating}
                setCreating={setCreating}
            />}
            {isCourses && <CourseContent
                type={type}
                isCreating={isCreating}
                setCreating={setCreating}
            />}
        </Content>
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ShiftContainer.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ShiftContainer;
