import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Action               from "Dashboard/Core/Action";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import TicketEdit           from "./TicketEdit";
import TicketMessage        from "./TicketMessage";
import TicketReply          from "./TicketReply";

// Dashboard
import Dialog               from "Dashboard/Components/Dialog/Dialog";
import DialogBody           from "Dashboard/Components/Dialog/DialogBody";
import DialogHeader         from "Dashboard/Components/Dialog/DialogHeader";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import Subtitle             from "Dashboard/Components/Header/Subtitle";
import InfoList             from "Dashboard/Components/Info/InfoList";
import InfoItem             from "Dashboard/Components/Info/InfoItem";
import InfoAction           from "Dashboard/Components/Info/InfoAction";



// Styles
const None = Styled.div`
    padding: 32px;
    font-size: 16px;
    font-weight: bold;
`;

const Header = Styled(InfoList)`
    position: sticky;
    top: 0;
    padding-top: 0;
    background-color: white;
    border-bottom: 1px solid var(--border-color-light);
    z-index: 1;

    @media (max-width: 600px) {
        .actions {
            display: none;
        }
    }
`;



/**
 * The Ticket View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TicketView(props) {
    const { open, elemID, onClose } = props;

    const { charging, messages, canModify, canOpen, canClose, canNote } = Store.useState("ticket");
    const {
        fetchElem, openTicket, closeTicket,
        editMessage, deleteMessage, deleteFile,
    } = Store.useAction("ticket");


    // The References
    const contentRef = React.useRef(null);

    // The Current State
    const [ action,  setAction  ] = Action.useAction();
    const [ update,  setUpdate  ] = React.useState(false);
    const [ message, setMessage ] = React.useState(null);

    // Load the Data
    const { elem } = useDialog("ticket", open, elemID, { withCharging : true });


    // Handles the submit
    const handleSubmit = () => {
        setUpdate(true);
        window.setTimeout(() => scrollToBottom(), 100);
    };

    // Scrolls to the Bottom of the Chat
    const scrollToBottom = () => {
        const node = contentRef.current;
        if (node) {
            node.scrollTop = node.scrollHeight - node.offsetHeight;
        }
    };

    // Handles the Message Edit Submit
    const handleMessageEdit = async (text) => {
        if (text) {
            await editMessage(elem.ticketID, message.ticketMessageID, text);
            endAction(true);
        }
    };

    // Handles the File Delete Submit
    const handleFileDelete = async () => {
        if (message) {
            await deleteFile(elem.ticketID, message.ticketMessageID);
            endAction(true);
        }
    };

    // Handles the Message Delete Submit
    const handleMessageDelete = async () => {
        if (message) {
            await deleteMessage(elem.ticketID, message.ticketMessageID);
            endAction(true);
        }
    };


    // Starts an Action
    const startAction = (action, message) => {
        setAction(action);
        setMessage(message);
    };

    // Ends an Action
    const endAction = (update) => {
        setAction();
        if (update !== undefined) {
            fetchElem(elemID);
            setUpdate(update);
        }
    };

    // Edits the Ticket
    const handleEdit = () => {
        endAction(true);
    };

    // Handles the Open Submit
    const handleOpen = async () => {
        if (canOpen) {
            await openTicket(elemID);
            endAction(true);
        }
    };

    // Handles the Close Submit
    const handleClose = async () => {
        if (canClose) {
            await closeTicket(elemID);
            endAction(true);
        }
    };


    // Variables
    const forMessage = Boolean(message && message.ticketMessageID);
    const forNote    = Boolean(message && message.isNote);


    // Do the Render
    return <>
        <Dialog
            open={open}
            isLoading={charging}
            onClose={() => onClose(update)}
            isWide
        >
            <DialogHeader
                icon="ticket"
                message={elem.title || "GENERAL_LOADING"}
            >
                <Subtitle
                    message={elem.statusName}
                    circle={elem.statusColor}
                    withBorder={false}
                />
            </DialogHeader>
            <DialogBody contentRef={contentRef}>
                <Header variant="outlined" onAction={startAction}>
                    <InfoItem label="GENERAL_PRIORITY"            message={elem.priorityName}         />
                    <InfoItem label="TICKET_AREAS_SINGULAR"       message={elem.ticketAreaName}       />
                    <InfoItem label="TICKET_DEPARTMENTS_SINGULAR" message={elem.ticketDepartmentName} />
                    <InfoItem label="TICKET_SUBJECTS_SINGULAR"    message={elem.ticketSubjectName}    />
                    <InfoItem label="TICKET_WAITLISTS_SINGULAR"   message={elem.ticketWaitlistName}   />
                    <InfoItem label="TICKETS_ASSIGNED"            message={elem.credentialName}       />

                    <InfoAction action="EDIT"  isHidden={!canModify} />
                    <InfoAction action="OPEN"  isHidden={!canOpen}   />
                    <InfoAction action="CLOSE" isHidden={!canClose}  />
                </Header>

                {!messages.length ? <None>{NLS.get("TICKETS_NONE_MESSAGES")}</None> : <div>
                    {messages.map((elem) => <TicketMessage
                        key={elem.ticketMessageID}
                        elem={elem}
                        startAction={startAction}
                    />)}
                </div>}

                <TicketReply
                    elemID={elem.ticketID}
                    canNote={canNote}
                    onSubmit={handleSubmit}
                />
            </DialogBody>
        </Dialog>

        <TicketEdit
            open={!forMessage && action.isCE}
            elemID={elem.ticketID}
            onSubmit={handleEdit}
            onClose={endAction}
        />

        <ConfirmDialog
            open={action.isOpen}
            icon="status"
            title="TICKETS_OPEN_TITLE"
            message="TICKETS_OPEN_TEXT"
            content={elem.id}
            onSubmit={handleOpen}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isClose}
            icon="status"
            title="TICKETS_CLOSE_TITLE"
            message="TICKETS_CLOSE_TEXT"
            content={elem.id}
            onSubmit={handleClose}
            onClose={endAction}
        />

        <PromptDialog
            open={forMessage && action.isEdit}
            icon="edit"
            title={forNote ? "TICKETS_EDIT_NOTE" : "TICKETS_EDIT_MESSAGE"}
            inputType="textarea"
            inputLabel={forNote ? "TICKETS_WRITE_NOTE" : "TICKETS_WRITE_MESSAGE"}
            initialValue={forMessage ? message.message : ""}
            onSubmit={handleMessageEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={forMessage && action.isFile}
            title="TICKETS_DELETE_FILE"
            message="TICKETS_DELETE_FILE_TEXT"
            content={forMessage ? message.messageShort : ""}
            onSubmit={handleFileDelete}
            onClose={endAction}
        />
        <DeleteDialog
            open={forMessage && action.isDelete}
            title={forNote ? "TICKETS_DELETE_NOTE" : "TICKETS_DELETE_MESSAGE"}
            message={forNote ? "TICKETS_DELETE_NOTE_TEXT" : "TICKETS_DELETE_MESSAGE_TEXT"}
            content={forMessage ? message.messageShort : ""}
            onSubmit={handleMessageDelete}
            onClose={endAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TicketView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default TicketView;
