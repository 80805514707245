import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Commons              from "Utils/Commons";

// Components
import CourseEdit           from "./CourseEdit";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Course Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CourseContent(props) {
    const { type, isCreating, setCreating } = props;
    const { fetch, load } = useList("shiftCourse", type);

    const activityID = Commons.useActivityID();

    const data = Store.useState("shiftCourse");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteElem } = Store.useAction("shiftCourse");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Open the Create Dialog
    React.useEffect(() => {
        if (isCreating) {
            startAction("CREATE");
        }
    }, [ isCreating ]);

    // Handles the Edit Submit
    const handleEdit = () => {
        handleClose();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        handleClose();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Close
    const handleClose = () => {
        setCreating(false);
        endAction();
    };


    // Do the Render
    const elemName = Utils.getValue(list, "shiftCourseID", elemID, "name");

    return <>
        <Table
            fetch={load}
            sort={sort}
            none="COURSES_NONE_AVAILABLE"
            isLoading={loading}
            hasTabs
        >
            <TableHead>
                <TableHeader field="name"     message="GENERAL_NAME"     isTitle bigMobile />
                <TableHeader field="isPast"   message="COURSES_IS_PAST"  align="center" />
                <TableHeader field="status"   message="GENERAL_STATUS"   maxWidth="100" align="center" />
                <TableHeader field="position" message="GENERAL_POSITION" maxWidth="80"  align="center" hideMobile />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.shiftCourseID} elemID={elem.shiftCourseID}>
                    <TableCell message={elem.name}       />
                    <TableCell message={elem.isPastText} />
                    <TableCell message={elem.statusName} textColor={elem.statusColor} />
                    <TableCell message={elem.position}   />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
            <TableActionList onAction={startAction} canEdit={canEdit}>
                <TableAction action="EDIT"   message="COURSES_EDIT_TITLE"   />
                <TableAction action="DELETE" message="COURSES_DELETE_TITLE" />
            </TableActionList>
        </Table>

        <CourseEdit
            open={action.isVCE}
            elemID={elemID}
            activityID={activityID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
        <DeleteDialog
            open={action.isDelete}
            title="COURSES_DELETE_TITLE"
            message="COURSES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={handleClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CourseContent.propTypes = {
    type        : PropTypes.string.isRequired,
    isCreating  : PropTypes.bool.isRequired,
    setCreating : PropTypes.func.isRequired,
};

export default CourseContent;
