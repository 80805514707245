const strings = {

    TITLE                                   : "CNSI",
    LANGUAGES                               : { "es" : "Español", "en" : "English" },



    // General
    GENERAL_ACCEPT                          : "Aceptar",
    GENERAL_ACCESS                          : "Rol",
    GENERAL_ACTIONS                         : "Acciones",
    GENERAL_ADD                             : "Agregar",
    GENERAL_ADD_DAY                         : "Agregar día",
    GENERAL_ADD_FIELD                       : "Agregar valor",
    GENERAL_ADD_MEMBER                      : "Agregar socio",
    GENERAL_ADD_PERIOD                      : "Agregar periodo",
    GENERAL_AGE                             : "Edad",
    GENERAL_ALL                             : "Todos",
    GENERAL_ALL_FEM                         : "Todas",
    GENERAL_AMOUNT                          : "Monto",
    GENERAL_AVAILABLE_FROM                  : "Disponible desde",
    GENERAL_AVAILABLE_FROM_DATE             : "Disponible desde la fecha",
    GENERAL_AVAILABLE_FROM_HOUR             : "Disponible desde la hora",
    GENERAL_AVAILABLE_TO                    : "Disponible hasta",
    GENERAL_AVAILABLE_TO_DATE               : "Disponible hasta la fecha",
    GENERAL_AVAILABLE_TO_HOUR               : "Disponible hasta la hora",
    GENERAL_BIRTH                           : "Nacimiento",
    GENERAL_BIRTH_DATE                      : "Fecha de nacimiento",
    GENERAL_CANCEL                          : "Cancelar",
    GENERAL_CELLPHONE                       : "Celular",
    GENERAL_CHARACTERS                      : "Caracteres: {0}",
    GENERAL_CHARACTERS_MAX                  : "Caracteres: {0}/{1}",
    GENERAL_CLOSE                           : "Cerrar",
    GENERAL_CREATE                          : "Crear",
    GENERAL_CODE                            : "Código",
    GENERAL_COLOR                           : "Color",
    GENERAL_COMMENTS                        : "Comentarios",
    GENERAL_CONFIRM                         : "Confirmar",
    GENERAL_CONTENT                         : "Contenido",
    GENERAL_CREATED_AT                      : "Creado el",
    GENERAL_CREATED_AT_FEM                  : "Creada el",
    GENERAL_DATE                            : "Fecha",
    GENERAL_DAY                             : "Día",
    GENERAL_DAYS                            : "Días",
    GENERAL_DELETE                          : "Eliminar",
    GENERAL_DESCRIPTION                     : "Descripción",
    GENERAL_DISCARD                         : "Descartar",
    GENERAL_DNI                             : "DNI",
    GENERAL_DOCUMENT_TYPE                   : "Tipo documento",
    GENERAL_DOCUMENT_VALUE                  : "Documento",
    GENERAL_DOWNLOAD                        : "Descargar",
    GENERAL_DURATION                        : "Duración",
    GENERAL_EDIT                            : "Editar",
    GENERAL_EMAIL                           : "Email",
    GENERAL_EXPORT                          : "Exportar",
    GENERAL_FAIL                            : "Fallido",
    GENERAL_FILE                            : "Archivo",
    GENERAL_FILE_SIZE_TIP                   : "El archivo debe ser menor a 8mb.",
    GENERAL_FILTER                          : "Filtrar",
    GENERAL_FIRST_NAME                      : "Nombre",
    GENERAL_FROM                            : "Desde",
    GENERAL_FROM_DATE                       : "Desde la fecha",
    GENERAL_FROM_HOUR                       : "Desde la hora",
    GENERAL_FULL_NAME                       : "Nombre y apellido",
    GENERAL_HELP                            : "Ayuda",
    GENERAL_ID                              : "ID",
    GENERAL_ICON                            : "Icono",
    GENERAL_IMAGE                           : "Imagen",
    GENERAL_IMAGE_URL                       : "Url de la imagen",
    GENERAL_INFO                            : "Información",
    GENERAL_IS_ACTIVE                       : "Está activo",
    GENERAL_IS_DEFAULT                      : "Usar por defecto",
    GENERAL_LAST_NAME                       : "Apellido",
    GENERAL_LOADING                         : "Cargando",
    GENERAL_LOADING_DOTS                    : "Cargando ...",
    GENERAL_LOCATION                        : "Dirección",
    GENERAL_LOGIN_AS                        : "Ingresar como usuario",
    GENERAL_LOGIN_TIME                      : "Último ingreso",
    GENERAL_LOGOUT                          : "Salir",
    GENERAL_MEMBER                          : "Socio",
    GENERAL_MEMBER_NUMBER                   : "Número de socio",
    GENERAL_MEMBER_RESPONSIBLE              : "Socio responsable",
    GENERAL_MESSAGE                         : "Mensaje",
    GENERAL_MODIFIED_AT                     : "Modificado el",
    GENERAL_MONTH                           : "Mes",
    GENERAL_NAME                            : "Nombre",
    GENERAL_NEW_PASSWORD                    : "Nueva contraseña",
    GENERAL_NO                              : "No",
    GENERAL_NONE_RESULTS                    : "No se encontraron resultados",
    GENERAL_NULLIFY                         : "Anular",
    GENERAL_NUMBER                          : "Número",
    GENERAL_OBSERVATIONS                    : "Observaciones",
    GENERAL_ONE_ERROR                       : "Error",
    GENERAL_OPEN                            : "Abrir",
    GENERAL_OTHERS                          : "Otros",
    GENERAL_PASSWORD                        : "Contraseña",
    GENERAL_PASSWORD_CONFIRM                : "Confirmar contraseña",
    GENERAL_PAGE                            : "Página {0} de {1}",
    GENERAL_PAGE_OF                         : "{0}-{1} de {2}",
    GENERAL_PENDING                         : "Pendiente",
    GENERAL_PERIOD_TITLE                    : "Periodo {0}",
    GENERAL_PERIODS                         : "Periodos",
    GENERAL_PERIODS_SINGULAR                : "1 periodo",
    GENERAL_PERIODS_PLURAL                  : "{0} periodos",
    GENERAL_PHONE                           : "Teléfono",
    GENERAL_POSITION                        : "Posición",
    GENERAL_PRIORITY                        : "Prioridad",
    GENERAL_QUANTITY                        : "Cantidad",
    GENERAL_REQ_PASS_CHANGE                 : "Requerir un cambio de contraseña luego de ingresar",
    GENERAL_REMOVE_FILTER                   : "Quitar filtro",
    GENERAL_RESULT                          : "Resultado",
    GENERAL_ROWS_PER_PAGE                   : "Filas a mostrar:",
    GENERAL_SAVE                            : "Guardar",
    GENERAL_SEARCH                          : "Buscar",
    GENERAL_SEARCHING                       : "Buscando ...",
    GENERAL_SELECT                          : "Seleccionar",
    GENERAL_SELECT_FILE                     : "Seleccionar archivo",
    GENERAL_SELECT_IMAGE                    : "Seleccionar imagen",
    GENERAL_SEND_EMAILS                     : "Enviar emails",
    GENERAL_SEND_TO                         : "Enviar a",
    GENERAL_SENT                            : "Enviado",
    GENERAL_SENT_TO                         : "Enviado el",
    GENERAL_SETTINGS                        : "Configuración",
    GENERAL_SHOW_IN_APP                     : "Mostrar en la app",
    GENERAL_SUCCESS                         : "Exitoso",
    GENERAL_SUBJECT                         : "Asunto",
    GENERAL_STATUS                          : "Estado",
    GENERAL_START                           : "Inicio",
    GENERAL_TEXT                            : "Texto",
    GENERAL_TIME                            : "Tiempo",
    GENERAL_TITLE                           : "Título",
    GENERAL_TO                              : "Hasta",
    GENERAL_TO_DATE                         : "Hasta la fecha",
    GENERAL_TO_HOUR                         : "Hasta la hora",
    GENERAL_TOTAL                           : "Total",
    GENERAL_TYPE                            : "Tipo",
    GENERAL_URL                             : "URL",
    GENERAL_USER                            : "Usuario",
    GENERAL_VIEW                            : "Ver",
    GENERAL_VIEW_FILE                       : "Ver archivo {0}",
    GENERAL_YEAR                            : "Año",
    GENERAL_YES                             : "Si",
    GENERAL_WAIT                            : "Esperar",
    GENERAL_WHATSAPP                        : "WhatsApp",

    GENERAL_ERROR                           : "Ocurrió un error inesperado. Estamos trabajando en resolverlo.",
    GENERAL_ERROR_API                       : "Ocurrió un error en la comunicación con el servidor.",
    GENERAL_ERROR_PATH                      : "Ruta incorrecta.",

    GENERAL_ERROR_ACCESS_EMPTY              : "Debe indicar un rol.",
    GENERAL_ERROR_ACCESS_INVALID            : "El rol indicado es incorrecto.",
    GENERAL_ERROR_AGE_RANGE                 : "Debe indicar una edad válida.",
    GENERAL_ERROR_BIRTH_DATE_EMPTY          : "Por favor, complete la fecha de nacimiento.",
    GENERAL_ERROR_BIRTH_DATE_INVALID        : "La fecha de nacimiento es incorrecta. Use el formato DD-MM-YYYY.",
    GENERAL_ERROR_COLOR                     : "El color es incorrecto.",
    GENERAL_ERROR_DATE_INVALID              : "El formato de la fecha es incorrecto.",
    GENERAL_ERROR_DATE_PAST                 : "La fecha debe ser posterior al día actual.",
    GENERAL_ERROR_DATE_PERIOD               : "La fecha hasta debe ser posterior a la fecha desde.",
    GENERAL_ERROR_DOCUMENT_TYPE             : "Debe indicar un tipo de documento válido.",
    GENERAL_ERROR_DOCUMENT_VALUE            : "Debe indicar un valor de documento válido.",
    GENERAL_ERROR_EDIT                      : "No tiene permiso para editar.",
    GENERAL_ERROR_EMAIL_EMPTY               : "Debe completar el email.",
    GENERAL_ERROR_EMAIL_INVALID             : "El email indicado es incorrecto.",
    GENERAL_ERROR_EMAIL_USED                : "El email indicado esta siendo usado por otra cuenta.",
    GENERAL_ERROR_FILE_EMPTY                : "Debe completar el archivo.",
    GENERAL_ERROR_FILE_EXISTS               : "No existe el archivo indicado.",
    GENERAL_ERROR_FILE_SIZE                 : "El archivo pesa más de 8mb.",
    GENERAL_ERROR_FILE_PDF                  : "El archivo debe ser un PDF.",
    GENERAL_ERROR_FIRST_NAME                : "Debe completar el nombre.",
    GENERAL_ERROR_FULL_NAME                 : "Debe completar el nombre y apellido.",
    GENERAL_ERROR_FROM_DATE_EMPTY           : "Debe indicar la fecha desde.",
    GENERAL_ERROR_FROM_DATE_INVALID         : "La fecha desde es inválida.",
    GENERAL_ERROR_FROM_DATE_PAST            : "La fecha desde debe ser posterior al día actual.",
    GENERAL_ERROR_FROM_HOUR_EMPTY           : "Debe indicar la hora desde.",
    GENERAL_ERROR_FROM_HOUR_INVALID         : "La hora desde es inválida.",
    GENERAL_ERROR_HOUR_PERIOD               : "La hora hasta debe ser posterior a la hora desde.",
    GENERAL_ERROR_IMAGE_TYPE                : "El formato de la imagen es incorrecto.",
    GENERAL_ERROR_IMAGE_EXISTS              : "No existe la imagen indicada.",
    GENERAL_ERROR_LAST_NAME                 : "Debe completar el apellido.",
    GENERAL_ERROR_MEMBER_NUMBER_EMPTY       : "Debe indicar el número de socio.",
    GENERAL_ERROR_MEMBER_NUMBER_INVALID     : "El número de socio indicado es inválido.",
    GENERAL_ERROR_MEMBER_NUMBER_REPEATED    : "No puede repetir los socios invitados.",
    GENERAL_ERROR_PASSWORD_EMPTY            : "Debe completar la contraseña.",
    GENERAL_ERROR_PASSWORD_INVALID          : "La contraseña debe ser entre 6 y 20 caracteres y contener al menos un número y una letra.",
    GENERAL_ERROR_PERIOD_DAYS_EMPTY         : "Debe indicar al menos un día.",
    GENERAL_ERROR_PERIOD_DAYS_INVALID       : "Uno de los días indicados es incorrecto.",
    GENERAL_ERROR_PERIOD_FROM_TIME          : "El tiempo desde indicado es incorrecto.",
    GENERAL_ERROR_PERIOD_TO_TIME            : "El tiempo hasta indicado es incorrecto.",
    GENERAL_ERROR_PERIOD_FROM_TO            : "El tiempo desde debe ser anterior al tiempo hasta.",
    GENERAL_ERROR_STATUS                    : "No existe el estado indicado.",
    GENERAL_ERROR_TO_DATE_EMPTY             : "Debe indicar la fecha hasta.",
    GENERAL_ERROR_TO_DATE_INVALID           : "La fecha de hasta es inválida.",
    GENERAL_ERROR_TO_HOUR_EMPTY             : "Debe indicar la hora hasta.",
    GENERAL_ERROR_TO_HOUR_INVALID           : "La hora hasta es inválida.",
    GENERAL_ERROR_USER_EXISTS               : "No existe el usuario indicado.",



    // Selects
    GENERAL_YES_NO                          : {
        0 : "No",
        1 : "Si",
    },
    GENERAL_YES_NO_ANY                      : {
        0 : "Cualquiera",
        1 : "No",
        2 : "Si",
    },
    GENERAL_YES_BLANK                       : {
        0 : "",
        1 : "Si",
    },
    SELECT_DOCUMENT_TYPES                   : {
        1 : "DNI",
        2 : "Pasaporte",
    },
    SELECT_AGE_RANGES                       : {
        1 : "Menores de 8 años",
        2 : "De 8 a 11 años",
        3 : "De 12 a 17 años",
        4 : "Mayores de 18 años",
    },
    SELECT_PERSON_TYPES                     : {
        "Member" : "Socio",
        "Minor"  : "Menor de 8 años",
    },
    SELECT_SUBSCRIPTION_TYPES               : {
        "Me"    : "Me deseo inscribir yo",
        "Other" : "Inscribir a otra persona",
    },
    SELECT_MATCH_TYPES                      : {
        "Singles" : "Singles",
        "Doubles" : "Dobles",
    },
    SELECT_PLAYER_TYPES                     : {
        "Member" : "Socio",
        "Guest"  : "Invitado",
    },
    SELECT_SOCIAL_TYPES                     : {
        "Emergency" : "Emergencia",
        "Facebook"  : "Facebook",
        "Instagram" : "Instagram",
    },
    SELECT_PRIORITIES                       : {
        "Low"    : "Baja",
        "Medium" : "Media",
        "High"   : "Alta",
    },
    SELECT_ENVIRONMENTS                     : {
        "Local" : "Local",
        "Dev"   : "Desarrollo",
        "Prod"  : "Producción",
    },
    SELECT_EMAIL_RESULTS                    : {
        "Sent"            : "Email enviado",
        "NotProcessed"    : "Email sin procesar",
        "NoEmails"        : "Sin emails a enviar",
        "InactiveSend"    : "Envío inactivo",
        "WhiteListFilter" : "Filtrado de lista blanca",
        "InvalidEmail"    : "Email inválido",
        "ProviderError"   : "Error en el proveedor",
        "UnknownError"    : "Error desconocido",
    },



    // Auth: Login
    LOGIN_TITLE                             : "Inicie sesión para continuar",
    LOGIN_RECOVER                           : "¿Olvidó su contraseña?",
    LOGIN_BUTTON                            : "Ingresar",
    LOGIN_TERMS_PRE                         : "Operar en CNSI implica aceptar los ",
    LOGIN_TERMS_LINK                        : "términos y condiciones",
    LOGIN_TERMS_POST                        : " en los que se ofrece el servicio.",
    LOGIN_ERROR_SPAM                        : "Ha intentado demasiadas veces.",
    LOGIN_ERROR_CREDENTIALS                 : "El email o la contraseña es incorrecto.",
    LOGIN_ERROR_DISABLED                    : "El ingreso esta deshabilitado.",

    // Auth: Recover Password
    RECOVER_TITLE                           : "Recuperar contraseña",
    RECOVER_HELP                            : "Use el siguiente formulario para recuperar su contraseña. Un mail será enviado a la dirección indicada con instrucciones acerca de como completar el proceso.",
    RECOVER_ACCOUNT                         : "Ingresar con su cuenta",
    RECOVER_BUTTON                          : "Enviar Email",
    RECOVER_SUCCESS                         : "Se ha enviado el email correctamente.",

    // Auth: Code
    CODE_TITLE                              : "Completar código",
    CODE_HELP                               : "Se ha enviado un mail a la dirección de mail indicada con un vínculo que debe hacer click para poder generar una nueva contraseña para su cuenta, o copiar y pegar el código a continuación.",
    CODE_ACCOUNT                            : "Ingresar con su cuenta",
    CODE_BUTTON                             : "Verificar código",
    CODE_ERROR_EMPTY                        : "Debe completar el código.",
    CODE_ERROR_INVALID                      : "El código indicado es incorrecto o se creó hace más de 3 horas. Debe solicitar otro.",
    CODE_SUCCESS                            : "El código se ha verificado correctamente.",

    // Auth: Reset Password
    RESET_TITLE                             : "Resetear contraseña",
    RESET_HELP                              : "Ingrese la contraseña que desea utilizar para acceder a su cuenta.",
    RESET_ACCOUNT                           : "Ingresar con su cuenta",
    RESET_BUTTON                            : "Resetear",
    RESET_ERROR_CODE                        : "Debe proveer un código.",
    RESET_ERROR_INVALID                     : "El código indicado es incorrecto o ha expirado.",
    RESET_ERROR_PASSWORD                    : "Debe completar la nueva contraseña.",
    RESET_ERROR_CONFIRM                     : "Debe confirmar la nueva contraseña.",
    RESET_ERROR_MATCH                       : "Las contraseñas no coinciden.",
    RESET_SUCCESS                           : "Su contraseña fue modificada correctamente.",

    // Auth: Change Password
    CHANGE_TITLE                            : "Cambiar contraseña",
    CHANGE_HELP                             : "Debe cambiar su contraseña por una nueva.",
    CHANGE_OLD_PASSWORD                     : "Contraseña actual",
    CHANGE_NEW_PASSWORD                     : "Nueva contraseña",
    CHANGE_BUTTON                           : "Cambiar contraseña",
    CHANGE_ERROR_INVALID                    : "El email indicado es incorrecto o no requiere cambios.",
    CHANGE_ERROR_OLD                        : "Debe completar su contraseña actual.",
    CHANGE_ERROR_NEW                        : "Debe completar la nueva contraseña.",
    CHANGE_ERROR_CONFIRM                    : "Debe confirmar la nueva contraseña.",
    CHANGE_ERROR_PASSWORD                   : "La contraseña actual es incorrecta.",
    CHANGE_ERROR_DIFFERENT                  : "La nueva contraseña debe ser diferente a la actual.",
    CHANGE_ERROR_MATCH                      : "Las contraseñas no coinciden.",
    CHANGE_SUCCESS                          : "Su contraseña fue modificada correctamente.",



    // Account
    ACCOUNT_NAME                            : "Cuenta",
    ACCOUNT_MINE                            : "Mi cuenta",
    ACCOUNT_CHANGE_AVATAR                   : "Cambiar foto",
    ACCOUNT_CONNECTIONS_TITLE               : "Dispositivos conectados",
    ACCOUNT_CONNECTIONS_BUTTON              : "Desconectar",
    ACCOUNT_DELETE_TITLE                    : "Eliminar mi cuenta",
    ACCOUNT_DELETE_TEXT                     : "¿Realmente desea eliminar su cuenta? Luego de eliminar su cuenta no podrá volver a entrar al sistema en ningún cliente y se borrarán sus datos.",
    ACCOUNT_DELETE_HELP                     : "Luego de eliminar la cuenta no podrá volver a entrar al sistema en ningún cliente y se borrarán sus datos.",
    ACCOUNT_ERROR_CONFIRM                   : "Debe confirmar su nueva contraseña.",
    ACCOUNT_ERROR_MATCH                     : "Las contraseñas son diferentes.",
    ACCOUNT_ERROR_AVATAR_EMPTY              : "Debe proporcionar una image.",
    ACCOUNT_ERROR_AVATAR_SIZE               : "La imagen es muy grande, intente con una más pequeña.",
    ACCOUNT_ERROR_AVATAR_TYPE               : "El archivo provisto no es una imagen.",
    ACCOUNT_SUCCESS_EDIT                    : "La cuenta fue editada correctamente.",
    ACCOUNT_SUCCESS_UPLOAD                  : "El avatar se subió correctamente.",

    // Categories
    CATEGORIES_NAME                         : "Categorías",
    CATEGORIES_SINGULAR                     : "Categoría",
    CATEGORIES_CREATE_TITLE                 : "Crear categoría",
    CATEGORIES_EDIT_TITLE                   : "Editar categoría",
    CATEGORIES_DELETE_TITLE                 : "Eliminar categoría",
    CATEGORIES_DELETE_TEXT                  : "¿Realmente desea eliminar la categoría <b>{0}</b>?",
    CATEGORIES_COUNT_SINGULAR               : "1 categoría",
    CATEGORIES_COUNT_PLURAL                 : "{0} categorías",
    CATEGORIES_NONE_AVAILABLE               : "No hay categorías disponibles.",
    CATEGORIES_ERROR_EXISTS                 : "No existe la categoría indicada.",
    CATEGORIES_ERROR_CODE_EMPTY             : "Debe completar el código de la categoría.",
    CATEGORIES_ERROR_CODE_EXISTS            : "Ya existe otra categoría con el código indicado.",
    CATEGORIES_ERROR_NAME_EMPTY             : "Debe indicar el nombre de la categoría.",
    CATEGORIES_ERROR_NAME_EXISTS            : "Ya existe otra categoría con el nombre indicado.",
    CATEGORIES_SUCCESS_CREATE               : "La categoría se creó correctamente.",
    CATEGORIES_SUCCESS_EDIT                 : "La categoría se editó correctamente.",
    CATEGORIES_SUCCESS_DELETE               : "La categoría se eliminó correctamente.",



    // Entries
    ENTRIES_NAME                            : "Entradas",
    ENTRIES_PUBLISH_DATE                    : "Fecha de publicación",
    ENTRIES_SUMMARY                         : "Resumen",
    ENTRIES_CONTENT                         : "Contenido",
    ENTRIES_SHOW_BEFORE                     : "Mostrar antes",
    ENTRIES_SHOW_BEFORE_PUBLISH             : "Mostrar antes de la publicación",

    // Entries: News
    NEWS_NAME                               : "Novedades",
    NEWS_CREATE_TITLE                       : "Crear novedad",
    NEWS_COPY_TITLE                         : "Copiar novedad",
    NEWS_EDIT_TITLE                         : "Editar novedad",
    NEWS_DELETE_TITLE                       : "Eliminar novedad",
    NEWS_DELETE_TEXT                        : "¿Realmente desea eliminar la novedad <b>{0}</b>?",
    NEWS_IN_HOME                            : "En el Inicio",
    NEWS_SHOW_IN_HOME                       : "Mostrar en el Inicio",
    NEWS_IN_NAZAR                           : "En Isla Nazar",
    NEWS_SHOW_IN_NAZAR                      : "Mostrar en Isla Nazar",
    NEWS_ADD_TITLE_BANNER                   : "Mostrar título sobre imagen",
    NEWS_NONE_AVAILABLE                     : "No hay novedades disponibles.",
    NEWS_ERROR_EXISTS                       : "No existe la novedad indicada.",
    NEWS_ERROR_CATEGORY                     : "Debe indicar la categoría de la novedad.",
    NEWS_ERROR_DATE                         : "Debe completar la fecha de publicación de la novedad.",
    NEWS_ERROR_TITLE_EMPTY                  : "Debe completar el título de la novedad.",
    NEWS_ERROR_TITLE_EXISTS                 : "Ya existe otra novedad con el título indicado.",
    NEWS_ERROR_SUMMARY                      : "Debe completar el resumen de la novedad.",
    NEWS_ERROR_CONTENT                      : "Debe completar el contenido de la novedad.",
    NEWS_ERROR_IMAGE                        : "Debe indicar la imagen de la novedad.",
    NEWS_SUCCESS_CREATE                     : "La novedad se creó correctamente.",
    NEWS_SUCCESS_EDIT                       : "La novedad se editó correctamente.",
    NEWS_SUCCESS_DELETE                     : "La novedad se eliminó correctamente.",

    // Entries: Billboard
    BILLBOARDS_NAME                         : "Cartelera",
    BILLBOARDS_CREATE_TITLE                 : "Crear publicación de cartelera",
    BILLBOARDS_COPY_TITLE                   : "Copiar publicación de cartelera",
    BILLBOARDS_EDIT_TITLE                   : "Editar publicación de cartelera",
    BILLBOARDS_DELETE_TITLE                 : "Eliminar publicación de cartelera",
    BILLBOARDS_DELETE_TEXT                  : "¿Realmente desea eliminar la publicación de cartelera <b>{0}</b>?",
    BILLBOARDS_NONE_AVAILABLE               : "No hay publicaciones del cartelera disponibles.",
    BILLBOARDS_ERROR_EXISTS                 : "No existe la publicación indicada.",
    BILLBOARDS_ERROR_DATE                   : "Debe completar la fecha de publicación de la publicación.",
    BILLBOARDS_ERROR_TITLE_EMPTY            : "Debe completar el título de la publicación.",
    BILLBOARDS_ERROR_TITLE_EXISTS           : "Ya existe otra publicación con el título indicado.",
    BILLBOARDS_ERROR_SUMMARY                : "Debe completar el resumen de la publicación.",
    BILLBOARDS_ERROR_CONTENT                : "Debe completar el contenido de la publicación.",
    BILLBOARDS_SUCCESS_CREATE               : "La publicación de cartelera se creó correctamente.",
    BILLBOARDS_SUCCESS_EDIT                 : "La publicación de cartelera se editó correctamente.",
    BILLBOARDS_SUCCESS_DELETE               : "La publicación de cartelera se eliminó correctamente.",



    // Activities
    ACTIVITIES_NAME                         : "Actividades",
    ACTIVITIES_SINGULAR                     : "Actividad",
    ACTIVITIES_VIEW_TITLE                   : "Ver actividad",
    ACTIVITIES_CREATE_TITLE                 : "Crear actividad",
    ACTIVITIES_EDIT_TITLE                   : "Editar actividad",
    ACTIVITIES_DELETE_TITLE                 : "Eliminar actividad",
    ACTIVITIES_DELETE_TEXT                  : "¿Realmente desea eliminar la actividad <b>{0}</b>?",
    ACTIVITIES_NONE_AVAILABLE               : "No hay actividades disponibles.",
    ACTIVITIES_ERROR_EXISTS                 : "No existe la actividad indicada.",
    ACTIVITIES_ERROR_NAME_EMPTY             : "Debe completar el nombre de la actividad.",
    ACTIVITIES_ERROR_NAME_EXISTS            : "Ya exista otra actividad con el nombre indicado.",
    ACTIVITIES_ERROR_IMAGE                  : "Debe indicar la imagen de la actividad.",
    ACTIVITIES_SUCCESS_CREATE               : "La actividad se creó correctamente.",
    ACTIVITIES_SUCCESS_EDIT                 : "La actividad se editó correctamente.",
    ACTIVITIES_SUCCESS_DELETE               : "La actividad se eliminó correctamente.",

    // Activities: Buttons
    BUTTONS_NAME                            : "Botones",
    BUTTONS_CREATE_TITLE                    : "Crear botón",
    BUTTONS_COPY_TITLE                      : "Copiar botón",
    BUTTONS_EDIT_TITLE                      : "Editar botón",
    BUTTONS_DELETE_TITLE                    : "Eliminar botón",
    BUTTONS_DELETE_TEXT                     : "¿Realmente desea eliminar el botón <b>{0}</b>?",
    BUTTONS_TYPE                            : "Tipo de botón",
    BUTTONS_CONTENT                         : "Página / URL",
    BUTTONS_ROUTE                           : "Ruta interna",
    BUTTONS_NONE_AVAILABLE                  : "No hay botones disponibles.",
    BUTTONS_ERROR_EXISTS                    : "No existe el botón indicado.",
    BUTTONS_ERROR_NAME                      : "Debe completar el nombre del botón.",
    BUTTONS_ERROR_TYPE_EMPTY                : "Debe indicar el tipo de botón.",
    BUTTONS_ERROR_TYPE_INVALID              : "El tipo de botón indicado es incorrecto.",
    BUTTONS_ERROR_URL_EMPTY                 : "Debe completar la URL del botón.",
    BUTTONS_ERROR_URL_INVALID               : "La URL debe iniciar en http:// o https://.",
    BUTTONS_ERROR_PAGE                      : "Debe indicar la página del botón.",
    BUTTONS_SUCCESS_CREATE                  : "El botón se creó correctamente.",
    BUTTONS_SUCCESS_EDIT                    : "El botón se editó correctamente.",
    BUTTONS_SUCCESS_DELETE                  : "El botón se eliminó correctamente.",

    // Activities: Pages
    PAGES_NAME                              : "Páginas",
    PAGES_SINGULAR                          : "Página",
    PAGES_CREATE_TITLE                      : "Crear página",
    PAGES_COPY_TITLE                        : "Copiar página",
    PAGES_EDIT_TITLE                        : "Editar página",
    PAGES_DELETE_TITLE                      : "Eliminar página",
    PAGES_DELETE_TEXT                       : "¿Realmente desea eliminar la página <b>{0}</b>?",
    PAGES_NONE_AVAILABLE                    : "No hay páginas disponibles.",
    PAGES_ERROR_EXISTS                      : "No existe la página indicada.",
    PAGES_ERROR_NAME_EMPTY                  : "Debe completar el nombre de la página.",
    PAGES_ERROR_NAME_EXISTS                 : "Ya existe otra página con el nombre indicado.",
    PAGES_ERROR_CONTENT                     : "Debe completar el contenido de la página.",
    PAGES_SUCCESS_CREATE                    : "La página se creó correctamente.",
    PAGES_SUCCESS_EDIT                      : "La página se editó correctamente.",
    PAGES_SUCCESS_DELETE                    : "La página se eliminó correctamente.",



    // Activity: Courses
    COURSES_NAME                            : "Cursos",
    COURSES_SINGULAR                        : "Curso",
    COURSES_VIEW_TITLE                      : "Ver curso",
    COURSES_CREATE_TITLE                    : "Crear curso",
    COURSES_EDIT_TITLE                      : "Editar curso",
    COURSES_COPY_TITLE                      : "Copiar curso",
    COURSES_CONFIRM_TITLE                   : "Confirmar curso",
    COURSES_CONFIRM_TEXT                    : "¿Realmente desea confirmar todas las inscripciones del curso <b>{0}</b>?<br><br>Al confirmar las inscripciones se enviará un email a cada socio con la información actualizada de su inscripción.",
    COURSES_DELETE_TITLE                    : "Eliminar curso",
    COURSES_DELETE_TEXT                     : "¿Realmente desea eliminar el curso <b>{0}</b>?",
    COURSES_IS_PAST                         : "Es pasado",
    COURSES_FROM_DATE                       : "Inicio del curso",
    COURSES_TO_DATE                         : "Fin del curso",
    COURSES_QUESTIONS                       : "Preguntas",
    COURSES_QUESTION_ANSWERS                : "Respuestas a las preguntas",
    COURSES_ADD_QUESTION                    : "Agregar preguntas",
    COURSES_GROUP                           : "Grupo",
    COURSES_GROUPS                          : "Grupos",
    COURSES_GROUPS_TITLE                    : "Grupo {0}",
    COURSES_ADD_GROUP                       : "Agregar grupo",
    COURSES_NONE_AVAILABLE                  : "No hay cursos disponibles.",
    COURSES_ERROR_EXISTS                    : "No existe el curso indicado.",
    COURSES_ERROR_SUBSCRIPTION_EXISTS       : "Ya tiene una inscripción para el curso indicado.",
    COURSES_ERROR_NAME                      : "Debe indicar el nombre del curso.",
    COURSES_ERROR_PERSONS                   : "Debe indicar al menos un niño/a.",
    COURSES_ERROR_PERSON_TYPE               : "El tipo de persona indicada es incorrecta.",
    COURSES_ERROR_BIRTH_DATE_YOUNG          : "El niño/a debe ser mayor a 3 años.",
    COURSES_ERROR_BIRTH_DATE_MINOR          : "El niño/a debe ser un socio al ser mayor a 8 años.",
    COURSES_ERROR_ANSWER_QUESTION           : "Por favor, responda la pregunta.",
    COURSES_SUCCESS_CREATE                  : "El curso se creó correctamente.",
    COURSES_SUCCESS_EDIT                    : "El curso se editó correctamente.",
    COURSES_SUCCESS_CONFIRM                 : "Las inscripciones se confirmaron correctamente.",
    COURSES_SUCCESS_DELETE                  : "El curso se eliminó correctamente.",

    // Activity: Courses: Persons
    COURSES_PERSONS_NAME                    : "Niños/as",
    COURSES_PERSONS_SINGULAR                : "Niño/a",
    COURSES_PERSONS_VIEW_TITLE              : "Ver niño/a",
    COURSES_PERSONS_EDIT_TITLE              : "Editar niños/as",
    COURSES_PERSONS_EDIT_SINGULAR           : "Puede editar el estado y el grupo del <b>{0}</b> niño/a seleccionado.",
    COURSES_PERSONS_EDIT_PLURAL             : "Puede editar el estado y el grupo de los <b>{0}</b> niños/as seleccionados.",
    COURSES_PERSONS_NONE_AVAILABLE          : "No hay niños/as disponibles.",
    COURSES_PERSONS_ERROR_EXISTS            : "No existe el niño/a indicado.",
    COURSES_PERSONS_ERROR_GROUP_EMPTY       : "Debe indicar el grupo del niño/a.",
    COURSES_PERSONS_ERROR_GROUP_INVALID     : "El grupo indicado es incorrecto.",
    COURSES_PERSONS_SUCCESS_EDIT            : "El niño/a se editó correctamente.",
    COURSES_PERSONS_SUCCESS_MULTI_EDIT      : "Los niños/as se editaron correctamente.",



    // Activity: Shifts
    SHIFTS_NAME                             : "Turnos",
    SHIFTS_VIEW_TITLE                       : "Ver turno",
    SHIFTS_CREATE_TITLE                     : "Crear turno",
    SHIFTS_EDIT_TITLE                       : "Editar turno",
    SHIFTS_COPY_TITLE                       : "Copiar turno",
    SHIFTS_DELETE_TITLE                     : "Eliminar turno",
    SHIFTS_DELETE_TEXT                      : "¿Realmente desea eliminar el turno <b>{0}</b>?",
    SHIFTS_AMOUNT                           : "Cantidad de turnos cada 30 mins",
    SHIFTS_EXTRA_AMOUNT                     : "Cantidad de turnos extra",
    SHIFTS_PAST_COURSES                     : "Cursos pasados",
    SHIFTS_REQ_COURSE                       : "Curso solicitado",
    SHIFTS_NONE_AVAILABLE                   : "No hay turnos disponibles.",
    SHIFTS_ERROR_EXISTS                     : "No existe el turno indicado.",
    SHIFTS_ERROR_EDIT                       : "No puede editar el turno indicado.",
    SHIFTS_ERROR_NAME                       : "Debe indicar el nombre del turno.",
    SHIFTS_ERROR_DATE_EMPTY                 : "Debe indicar la fecha del turno.",
    SHIFTS_ERROR_DATE_AVAILABLE             : "La fecha debe ser posterior a la fecha disponible hasta.",
    SHIFTS_ERROR_AMOUNT_EMPTY               : "Debe indicar la cantidad de turnos.",
    SHIFTS_ERROR_AMOUNT_INVALID             : "La cantidad de turnos debe ser un número mayor a 0.",
    SHIFTS_ERROR_EXTRA_AMOUNT               : "La cantidad de turnos extra debe ser un número mayor a 0.",
    SHIFTS_SUCCESS_CREATE                   : "El turno se creó correctamente.",
    SHIFTS_SUCCESS_EDIT                     : "El turno se editó correctamente.",
    SHIFTS_SUCCESS_DELETE                   : "El turno se eliminó correctamente.",



    // Activity: Trainings
    TRAININGS_NAME                          : "Entrenamientos",
    TRAININGS_SINGULAR                      : "Entrenamiento",
    TRAININGS_VIEW_TITLE                    : "Ver entrenamiento",
    TRAININGS_CREATE_TITLE                  : "Crear entrenamiento",
    TRAININGS_EDIT_TITLE                    : "Editar entrenamiento",
    TRAININGS_COPY_TITLE                    : "Copiar entrenamiento",
    TRAININGS_DELETE_TITLE                  : "Eliminar entrenamiento",
    TRAININGS_DELETE_TEXT                   : "¿Realmente desea eliminar el entrenamiento <b>{0}</b>?",
    TRAININGS_FILE                          : "Apto médico",
    TRAININGS_VIEW_FILE                     : "Ver apto médico",
    TRAININGS_USE_WHITE_LIST                : "Aprobar usando lista blanca",
    TRAININGS_NONE_AVAILABLE                : "No hay entrenamientos disponibles.",
    TRAININGS_ERROR_EXISTS                  : "No existe el entrenamiento indicado.",
    TRAININGS_ERROR_NAME                    : "Debe indicar el nombre del entrenamiento.",
    TRAININGS_SUCCESS_CREATE                : "El entrenamiento se creó correctamente.",
    TRAININGS_SUCCESS_EDIT                  : "El entrenamiento se editó correctamente.",
    TRAININGS_SUCCESS_DELETE                : "El entrenamiento se eliminó correctamente.",

    // Activity: Trainings: White Lists
    TRAININGS_WHITE_LISTS_NAME              : "Listas blancas",
    TRAININGS_WHITE_LISTS_SINGULAR          : "Lista blanca",
    TRAININGS_WHITE_LISTS_VIEW_TITLE        : "Ver lista blanca",
    TRAININGS_WHITE_LISTS_CREATE_TITLE      : "Crear lista blanca",
    TRAININGS_WHITE_LISTS_EDIT_TITLE        : "Editar lista blanca",
    TRAININGS_WHITE_LISTS_DELETE_TITLE      : "Eliminar lista blanca",
    TRAININGS_WHITE_LISTS_DELETE_TEXT       : "¿Realmente desea eliminar la lista blanca <b>{0}</b>?",
    TRAININGS_WHITE_LISTS_NONE_AVAILABLE    : "No hay listas blancas disponibles.",
    TRAININGS_WHITE_LISTS_ERROR_EXISTS      : "No existe la lista blanca indicada.",
    TRAININGS_WHITE_LISTS_ERROR_NAME        : "Debe indicar el nombre de la lista blanca.",
    TRAININGS_WHITE_LISTS_SUCCESS_CREATE    : "La lista blanca creó correctamente.",
    TRAININGS_WHITE_LISTS_SUCCESS_EDIT      : "La lista blanca editó correctamente.",
    TRAININGS_WHITE_LISTS_SUCCESS_DELETE    : "La lista blanca eliminó correctamente.",

    // Activity: Trainings: White List Member
    TRAININGS_MEMBERS_NAME                  : "Socios en lista blanca",
    TRAININGS_MEMBERS_NONE_AVAILABLE        : "No hay socios disponibles en la lista blanca.",
    TRAININGS_MEMBERS_CREATE_TITLE          : "Agregar a lista blanca",
    TRAININGS_MEMBERS_EDIT_TITLE            : "Editar en lista blanca",
    TRAININGS_MEMBERS_DELETE_TITLE          : "Eliminar de lista blanca",
    TRAININGS_MEMBERS_DELETE_TEXT           : "¿Realmente desea eliminar al socio <b>{0}</b> de la lista blanca?",
    TRAININGS_MEMBERS_ERROR_EXISTS          : "No existe el socio indicado en la lista blanca.",
    TRAININGS_MEMBERS_ERROR_ADDED           : "El socio ya fue agregado a la lista blanca.",
    TRAININGS_MEMBERS_SUCCESS_CREATE        : "El socio se agregó correctamente a la lista blanca.",
    TRAININGS_MEMBERS_SUCCESS_EDIT          : "El socio se editó correctamente en la lista blanca.",
    TRAININGS_MEMBERS_SUCCESS_DELETE        : "El socio se eliminó correctamente de la lista blanca.",



    // Activity: Subscriptions
    SUBSCRIPTIONS_NAME                      : "Inscripciones",
    SUBSCRIPTIONS_VIEW_TITLE                : "Ver inscripción",
    SUBSCRIPTIONS_CREATE_TITLE              : "Crear inscripción",
    SUBSCRIPTIONS_EDIT_TITLE                : "Editar inscripción",
    SUBSCRIPTIONS_ACTION                    : "Inscribir",
    SUBSCRIPTIONS_CHILD_TITLE               : "Niño/a {0}",
    SUBSCRIPTIONS_ADD_CHILD                 : "Agregar niño/a",
    SUBSCRIPTIONS_TYPE                      : "A quien inscribir",
    SUBSCRIPTIONS_MEMBER                    : "Socio a inscribir",
    SUBSCRIPTIONS_PENDING_TITLE             : "Marcar como pendiente",
    SUBSCRIPTIONS_PENDING_TEXT              : "¿Realmente desea marcar la inscripción de <b>{0}</b> como pendiente?",
    SUBSCRIPTIONS_NULLIFY_TITLE             : "Anular inscripción",
    SUBSCRIPTIONS_NULLIFY_TEXT              : "¿Realmente desea anular la inscripción de <b>{0}</b>?",
    SUBSCRIPTIONS_DELETE_TITLE              : "Eliminar inscripción",
    SUBSCRIPTIONS_DELETE_TEXT               : "¿Realmente desea eliminar la inscripción de <b>{0}</b>?",
    SUBSCRIPTIONS_NONE_AVAILABLE            : "No hay inscripciones disponibles.",
    SUBSCRIPTIONS_ERROR_EXISTS              : "No existe la inscripción indicada.",
    SUBSCRIPTIONS_ERROR_TYPE                : "Debe indicar a quien inscribir.",
    SUBSCRIPTIONS_SUCCESS_CREATE            : "La inscripción se creó correctamente.",
    SUBSCRIPTIONS_SUCCESS_EDIT              : "La inscripción se editó correctamente.",
    SUBSCRIPTIONS_SUCCESS_NULLIFY           : "La inscripción se anuló correctamente.",
    SUBSCRIPTIONS_SUCCESS_DELETE            : "La inscripción se eliminó correctamente.",



    // Reserves
    RESERVES_NAME                           : "Reservas",
    RESERVES_SINGULAR                       : "Reserva",
    RESERVES_OF                             : "Reservas de {0}",
    RESERVES_FILTER_TITLE                   : "Filtrar reservas",
    RESERVES_VIEW_TITLE                     : "Ver reserva",
    RESERVES_CREATE_TITLE                   : "Crear reserva",
    RESERVES_EDIT_TITLE                     : "Editar reserva",
    RESERVES_NULLIFY_TITLE                  : "Anular reserva",
    RESERVES_NULLIFY_TEXT                   : "¿Realmente desea anular la reserva indicada?",
    RESERVES_CURRENT_TAB                    : "Vigentes",
    RESERVES_PAST_TAB                       : "Pasadas",
    RESERVES_NULLIFIED_TAB                  : "Anuladas",
    RESERVES_FOR_DOUBLES                    : "Para dobles",
    RESERVES_DATE                           : "Fecha de reserva",
    RESERVES_HOUR                           : "Hora de reserva",
    RESERVES_DURATION                       : "Duración de reserva",
    RESERVES_MATCH_TYPE                     : "Tipo de partido",
    RESERVES_PLAYER                         : "Jugador",
    RESERVES_PLAYERS                        : "Jugadores",
    RESERVES_PLAYERS_TITLE                  : "Jugador invitado {0}",
    RESERVES_ADD_PLAYER                     : "Agregar jugador",
    RESERVES_PLAYER_TYPE                    : "Tipo de jugador",
    RESERVES_SHARES_PAYMENT                 : "Comparte el pago",
    RESERVES_IS_CONFIRMED                   : "Está confirmado",
    RESERVES_NONE_AVAILABLE                 : "No hay reservas disponibles.",
    RESERVES_ERROR_EXISTS                   : "No existe la reserva indicada.",
    RESERVES_ERROR_EDIT                     : "No puede editar la reserva indicada.",
    RESERVES_ERROR_DATE                     : "Debe indicar una fecha correcta para la reserva.",
    RESERVES_ERROR_HOURS                    : "No hay horarios disponibles para la fecha indicada.",
    RESERVES_ERROR_HOUR                     : "La hora indicada no está disponible.",
    RESERVES_ERROR_COURT                    : "La cancha indicada no está disponible.",
    RESERVES_ERROR_DOUBLES                  : "No se permite hacer una reserva para dobles",
    RESERVES_ERROR_DURATION                 : "La duración indicada no está disponible.",
    RESERVES_ERROR_PLAYER_TYPE              : "Debe indicar un tipo de jugador valido.",
    RESERVES_ERROR_PLAYERS_TOTAL            : "Superó la cantidad de jugadores posibles.",
    RESERVES_ERROR_INVITATION               : "No es posible crear o editar la invitación asociada.",
    RESERVES_ERROR_MAX_ACTIVE               : "La cantidad de reservas supera el máximo disponible.",
    RESERVES_ERROR_MAX_DAILY                : "La cantidad de reservas supera el máximo diario.",
    RESERVES_SUCCESS_CREATE                 : "La reserva se creó correctamente.",
    RESERVES_SUCCESS_EDIT                   : "La reserva se editó correctamente.",
    RESERVES_SUCCESS_NULLIFY                : "La reserva se anuló correctamente.",

    // Reserves: Calendar
    RESERVE_CALENDAR_NAME                   : "Calendario",
    RESERVE_CALENDAR_GOTO_TODAY             : "Ir a hoy",
    RESERVE_CALENDAR_GOTO_DATE              : "Ir a la fecha",
    RESERVE_CALENDAR_NONE_AVAILABLE         : "No hay tiempos disponibles para hacer reservas.",

    // Reserves: Court Block Types
    RESERVE_TYPES_NAME                      : "Tipos de reservas",
    RESERVE_TYPES_SINGULAR                  : "Tipo de reserva",
    RESERVE_TYPES_CREATE_TITLE              : "Crear tipo de reserva",
    RESERVE_TYPES_VIEW_TITLE                : "Ver tipo de reserva",
    RESERVE_TYPES_EDIT_TITLE                : "Editar tipo de reserva",
    RESERVE_TYPES_DELETE_TITLE              : "Eliminar tipo de reserva",
    RESERVE_TYPES_DELETE_TEXT               : "¿Realmente desea eliminar el tipo de reserva <b>{0}</b>?",
    RESERVE_TYPES_VISIT_TYPE                : "Tipo de visita",
    RESERVE_TYPES_ALLOWS_DOUBLES            : "Permite singles y dobles",
    RESERVE_TYPES_MAX_SINGLES               : "Jugadores de singles",
    RESERVE_TYPES_MAX_DOUBLES               : "Jugadores de dobles",
    RESERVE_TYPES_CONCEPT_SINGLES           : "Concepto facturación de singles",
    RESERVE_TYPES_CONCEPT_DOUBLES           : "Concepto facturación de dobles",
    RESERVE_TYPES_SINGLES_DURATION          : "Duración para singles (minutos)",
    RESERVE_TYPES_DOUBLES_DURATION          : "Duración para dobles (minutos)",
    RESERVE_TYPES_MAX_DAILY                 : "Máximo diarias",
    RESERVE_TYPES_MAX_DAILY_RESERVES        : "Máximo de reservas diarias",
    RESERVE_TYPES_MAX_ACTIVE                : "Máximo activas",
    RESERVE_TYPES_MAX_ACTIVE_RESERVES       : "Máximo de reservas activas",
    RESERVE_TYPES_DAYS_ANTICIPATION         : "Días de anticipación",
    RESERVE_TYPES_MINS_BEFORE_EDIT          : "Minutos antes de poder editar",
    RESERVE_TYPES_NONE_AVAILABLE            : "No hay tipos de reservas disponibles.",
    RESERVE_TYPES_ERROR_EXISTS              : "No existe el tipo de reserva indicado.",
    RESERVE_TYPES_ERROR_NAME_EMPTY          : "Debe indicar el nombre del tipo de reserva.",
    RESERVE_TYPES_ERROR_NAME_EXISTS         : "Ya existe otro tipo de reserva con el nombre indicado.",
    RESERVE_TYPES_ERROR_ACTIVITY            : "Debe indicar la actividad del tipo de reserva.",
    RESERVE_TYPES_ERROR_COURT_USED          : "Una de las canchas esta usada en otro tipo de reserva.",
    RESERVE_TYPES_ERROR_VISIT_TYPE_EMPTY    : "Debe indicar el tipo de visita.",
    RESERVE_TYPES_ERROR_VISIT_TYPE_INVALID  : "No existe el tipo de visita indicado.",
    RESERVE_TYPES_ERROR_MAX_SINGLES         : "La cantidad de jugadores de singles debe ser un número mayor a 0.",
    RESERVE_TYPES_ERROR_MAX_DOUBLES         : "La cantidad de jugadores de dobles debe ser un número mayor a 0.",
    RESERVE_TYPES_ERROR_CONCEPT_SINGLES     : "Debe indicar el concepto de facturación para singles.",
    RESERVE_TYPES_ERROR_CONCEPT_DOUBLES     : "Debe indicar el concepto de facturación para dobles.",
    RESERVE_TYPES_ERROR_PERIODS_EMPTY       : "Debe indicar al menos un período de reserva.",
    RESERVE_TYPES_ERROR_SINGLES_DURATION    : "La duración para singles debe ser un número mayor a 0.",
    RESERVE_TYPES_ERROR_DOUBLES_DURATION    : "La duración para dobles debe ser un número mayor a 0.",
    RESERVE_TYPES_ERROR_MAX_DAILY_RESERVES  : "El máximo de reservas diarias debe ser un número mayor a 0.",
    RESERVE_TYPES_ERROR_MAX_ACTIVE_RESERVES : "El máximo de reservas activas debe ser un número mayor a 0.",
    RESERVE_TYPES_ERROR_DAYS_ANTICIPATION   : "Los días de anticipación deben ser un número mayor a 0.",
    RESERVE_TYPES_ERROR_MINS_BEFORE_EDIT    : "Los minutos antes de poder editar deben ser un número mayor a 0.",
    RESERVE_TYPES_ERROR_USED                : "No puede eliminar un tipo de reserva en uso.",
    RESERVE_TYPES_SUCCESS_CREATE            : "El tipo de reserva se creó correctamente.",
    RESERVE_TYPES_SUCCESS_EDIT              : "El tipo de reserva se editó correctamente.",
    RESERVE_TYPES_SUCCESS_DELETE            : "El tipo de reserva se eliminó correctamente.",

    // Reserves: Courts
    COURTS_NAME                             : "Canchas",
    COURTS_SINGULAR                         : "Cancha",
    COURTS_CREATE_TITLE                     : "Crear cancha",
    COURTS_EDIT_TITLE                       : "Editar cancha",
    COURTS_DELETE_TITLE                     : "Eliminar cancha",
    COURTS_DELETE_TEXT                      : "¿Realmente desea eliminar la cancha <b>{0}</b>?",
    COURTS_COUNT_SINGULAR                   : "1 cancha",
    COURTS_COUNT_PLURAL                     : "{0} canchas",
    COURTS_NONE_AVAILABLE                   : "No hay canchas disponibles.",
    COURTS_ERROR_EXISTS                     : "No existe la cancha indicada.",
    COURTS_ERROR_NAME                       : "Debe indicar el nombre de la cancha.",
    COURTS_ERROR_ACTIVITY                   : "Debe indicar la actividad de la cancha.",
    COURTS_ERROR_USED                       : "No puede eliminar una cancha en uso.",
    COURTS_ERROR_COURTS_EMPTY               : "Debe indicar al menos una cancha.",
    COURTS_ERROR_COURTS_EXISTS              : "Alguna de las canchas indicadas no existe.",
    COURTS_SUCCESS_CREATE                   : "La cancha se creó correctamente.",
    COURTS_SUCCESS_EDIT                     : "La cancha se editó correctamente.",
    COURTS_SUCCESS_DELETE                   : "La cancha se eliminó correctamente.",

    // Reserves: Court Blocks
    COURT_BLOCKS_NAME                       : "Bloqueos de canchas",
    COURT_BLOCKS_SINGULAR                   : "Bloqueo de canchas",
    COURT_BLOCKS_FILTER_TITLE               : "Filtrar bloqueos",
    COURT_BLOCKS_CREATE_TITLE               : "Crear bloqueo de canchas",
    COURT_BLOCKS_EDIT_TITLE                 : "Editar bloqueo de canchas",
    COURT_BLOCKS_DELETE_TITLE               : "Eliminar bloqueo de canchas",
    COURT_BLOCKS_DELETE_TEXT                : "¿Realmente desea eliminar el bloqueo de canchas <b>{0}</b>?",
    COURT_BLOCKS_CURRENT_TAB                : "Vigentes",
    COURT_BLOCKS_COMPLETED_TAB              : "Completados",
    COURT_BLOCKS_INACTIVE_TAB               : "Inactivos",
    COURT_BLOCKS_REPEATS_WEEKLY             : "Repite semanalmente",
    COURT_BLOCKS_REPEATS_TEXT               : "{0} de {1} a {2}",
    COURT_BLOCKS_NONE_AVAILABLE             : "No hay bloqueos de canchas disponibles.",
    COURT_BLOCKS_ERROR_EXISTS               : "No existe el bloqueo de canchas indicado.",
    COURT_BLOCKS_ERROR_NAME                 : "Debe indicar el nombre del bloqueo de canchas.",
    COURT_BLOCKS_ERROR_TYPE                 : "Debe indicar el tipo de bloqueo del bloqueo de canchas.",
    COURT_BLOCKS_ERROR_ACTIVITY             : "Debe indicar la actividad del bloqueo de canchas.",
    COURT_BLOCKS_ERROR_END_REPEAT           : "La fecha del fin de la repetición del bloqueo de canchas es inválida.",
    COURT_BLOCKS_ERROR_REPEATS_DAY          : "El día de repetición es inválido.",
    COURT_BLOCKS_ERROR_INTERSECTS_RESERVE   : "Hay al menos una reserva en la fecha del bloqueo de canchas.",
    COURT_BLOCKS_SUCCESS_CREATE             : "El bloqueo de canchas se creó correctamente.",
    COURT_BLOCKS_SUCCESS_EDIT               : "El bloqueo de canchas se editó correctamente.",
    COURT_BLOCKS_SUCCESS_DELETE             : "El bloqueo de canchas se eliminó correctamente.",

    // Reserves: Court Block Types
    COURT_BLOCK_TYPES_NAME                  : "Tipos de bloqueos",
    COURT_BLOCK_TYPES_SINGULAR              : "Tipo de bloqueo",
    COURT_BLOCK_TYPES_CREATE_TITLE          : "Crear tipo de bloqueo",
    COURT_BLOCK_TYPES_EDIT_TITLE            : "Editar tipo de bloqueo",
    COURT_BLOCK_TYPES_DELETE_TITLE          : "Eliminar tipo de bloqueo",
    COURT_BLOCK_TYPES_DELETE_TEXT           : "¿Realmente desea eliminar el tipo de bloqueo <b>{0}</b>?",
    COURT_BLOCK_TYPES_PRIORITY_HELP         : "Un tipo de bloqueo con mayor prioridad se considerará antes que uno con menor prioridad.",
    COURT_BLOCK_TYPES_NONE_AVAILABLE        : "No hay tipos de bloqueos disponibles.",
    COURT_BLOCK_TYPES_ERROR_EXISTS          : "No existe el tipo de bloqueo indicado.",
    COURT_BLOCK_TYPES_ERROR_NAME            : "Debe indicar el nombre del tipo de bloqueo.",
    COURT_BLOCK_TYPES_ERROR_PRIORITY        : "Debe indicar la prioridad como un número mayor a 0.",
    COURT_BLOCK_TYPES_ERROR_USED            : "No puede eliminar un tipo de bloqueo en uso.",
    COURT_BLOCK_TYPES_SUCCESS_CREATE        : "El tipo de bloqueo se creó correctamente.",
    COURT_BLOCK_TYPES_SUCCESS_EDIT          : "El tipo de bloqueo se editó correctamente.",
    COURT_BLOCK_TYPES_SUCCESS_DELETE        : "El tipo de bloqueo se eliminó correctamente.",



    // Schedules
    SCHEDULES_NAME                          : "Horarios",
    SCHEDULES_SINGULAR                      : "Horario",
    SCHEDULES_CREATE_TITLE                  : "Crear horario",
    SCHEDULES_EDIT_TITLE                    : "Editar horario",
    SCHEDULES_COPY_TITLE                    : "Copiar horario",
    SCHEDULES_DELETE_TITLE                  : "Eliminar horario",
    SCHEDULES_DELETE_TEXT                   : "¿Realmente desea eliminar el horario <b>{0}</b>?",
    SCHEDULES_FOR_NAZAR                     : "Para Isla Nazar",
    SCHEDULES_CLOSED_DAYS                   : "Días cerrado",
    SCHEDULES_SHOW_AS_NO_SERVICE            : "Mostrar como \"Sin servicio\"",
    SCHEDULES_NONE_AVAILABLE                : "No hay horarios disponibles.",
    SCHEDULES_ERROR_EXISTS                  : "No existe el horario indicado.",
    SCHEDULES_ERROR_NAME_EMPTY              : "Debe indicar el nombre del horario.",
    SCHEDULES_ERROR_NAME_EXISTS             : "Ya existe otro horario con el nombre indicado.",
    SCHEDULES_ERROR_CATEGORY                : "Debe indicar la categoría del horario.",
    SCHEDULES_SUCCESS_CREATE                : "El horario se creó correctamente.",
    SCHEDULES_SUCCESS_EDIT                  : "El horario se editó correctamente.",
    SCHEDULES_SUCCESS_DELETE                : "El horario se eliminó correctamente.",

    // Schedules: Holidays
    HOLIDAYS_NAME                           : "Feriados",
    HOLIDAYS_CREATE_TITLE                   : "Crear feriado",
    HOLIDAYS_EDIT_TITLE                     : "Editar feriado",
    HOLIDAYS_DELETE_TITLE                   : "Eliminar feriado",
    HOLIDAYS_DELETE_TEXT                    : "¿Realmente desea eliminar el feriado <b>{0}</b>?",
    HOLIDAYS_NONE_AVAILABLE                 : "No hay feriados disponibles.",
    HOLIDAYS_ERROR_EXISTS                   : "No existe el feriado indicado.",
    HOLIDAYS_ERROR_NAME                     : "Debe indicar el nombre del feriado.",
    HOLIDAYS_ERROR_DAY                      : "Debe indicar el día del feriado.",
    HOLIDAYS_ERROR_MONTH                    : "Debe indicar un mes válido para el feriado.",
    HOLIDAYS_ERROR_YEAR                     : "Debe indicar un año válido para el feriado.",
    HOLIDAYS_ERROR_DELETE                   : "No puede eliminar el feriado indicado.",
    HOLIDAYS_SUCCESS_CREATE                 : "El feriado se creó correctamente.",
    HOLIDAYS_SUCCESS_EDIT                   : "El feriado se editó correctamente.",
    HOLIDAYS_SUCCESS_DELETE                 : "El feriado se eliminó correctamente.",



    // Administration
    ADMINISTRATION_NAME                    : "Administración",

    // Administration: Titles
    TITLES_NAME                            : "Títulos",
    TITLES_VIEW_TITLE                      : "Ver título",
    TITLES_EDIT_TITLE                      : "Editar título",
    TITLES_IMAGE_TIP                       : "La imagen debe ser de 1100px x 300px.",
    TITLES_NONE_AVAILABLE                  : "No hay títulos disponibles.",
    TITLES_ERROR_EXISTS                    : "No existe el título indicado.",
    TITLES_ERROR_NAME                      : "Debe indicar el nombre del título.",
    TITLES_SUCCESS_EDIT                    : "El título se editó correctamente.",

    // Administration: Fees
    FEES_NAME                              : "Tarifas",
    FEES_VIEW_TITLE                        : "Ver tarifa",
    FEES_CREATE_TITLE                      : "Crear tarifa",
    FEES_EDIT_TITLE                        : "Editar tarifa",
    FEES_DELETE_TITLE                      : "Eliminar tarifa",
    FEES_DELETE_TEXT                       : "¿Realmente desea eliminar la tarifa <b>{0}</b>?",
    FEES_NONE_AVAILABLE                    : "No hay tarifas disponibles.",
    FEES_ERROR_EXISTS                      : "No existe la tarifa indicada.",
    FEES_ERROR_NAME                        : "Debe indicar el nombre de la tarifa.",
    FEES_ERROR_URL                         : "Debe indicar la url del archivo.",
    FEES_SUCCESS_CREATE                    : "La tarifa se creó correctamente.",
    FEES_SUCCESS_EDIT                      : "La tarifa se editó correctamente.",
    FEES_SUCCESS_DELETE                    : "La tarifa se eliminó correctamente.",

    // Administration: Balances
    BALANCES_NAME                          : "Memoria y balance",
    BALANCES_VIEW_TITLE                    : "Ver memoria y balance",
    BALANCES_CREATE_TITLE                  : "Crear memoria y balance",
    BALANCES_EDIT_TITLE                    : "Editar memoria y balance",
    BALANCES_DELETE_TITLE                  : "Eliminar memoria y balance",
    BALANCES_DELETE_TEXT                   : "¿Realmente desea eliminar el memoria y balance <b>{0}</b>?",
    BALANCES_NONE_AVAILABLE                : "No hay memorias y balances disponibles.",
    BALANCES_ERROR_EXISTS                  : "No existe la memoria y balance indicada.",
    BALANCES_ERROR_NAME                    : "Debe indicar el nombre de la memoria y balance.",
    BALANCES_ERROR_URL                     : "Debe indicar la url del archivo.",
    BALANCES_SUCCESS_CREATE                : "La memoria y balance se creó correctamente.",
    BALANCES_SUCCESS_EDIT                  : "La memoria y balance se editó correctamente.",
    BALANCES_SUCCESS_DELETE                : "La memoria y balance se eliminó correctamente.",



    // Contacts
    CONTACTS_NAME                           : "Contactos",
    CONTACTS_SINGULAR                       : "Contacto",
    CONTACTS_CREATE_TITLE                   : "Crear contacto",
    CONTACTS_EDIT_TITLE                     : "Editar contacto",
    CONTACTS_DELETE_TITLE                   : "Eliminar contacto",
    CONTACTS_DELETE_TEXT                    : "¿Realmente desea eliminar el contacto <b>{0}</b>?",
    CONTACTS_NONE_AVAILABLE                 : "No hay contactos disponibles.",
    CONTACTS_ERROR_EXISTS                   : "No existe el contacto indicado.",
    CONTACTS_ERROR_NAME_EMPTY               : "Debe indicar el nombre del contacto.",
    CONTACTS_ERROR_NAME_EXISTS              : "Ya existe otro contacto con el nombre indicado.",
    CONTACTS_ERROR_CATEGORY                 : "Debe indicar la categoría del contacto.",
    CONTACTS_SUCCESS_CREATE                 : "El contacto se creó correctamente.",
    CONTACTS_SUCCESS_EDIT                   : "El contacto se editó correctamente.",
    CONTACTS_SUCCESS_DELETE                 : "El contacto se eliminó correctamente.",

    // Contacts: Sociales
    SOCIALS_NAME                            : "Redes sociales",
    SOCIALS_SINGULAR                        : "Red social",
    SOCIALS_CREATE_TITLE                    : "Crear red social",
    SOCIALS_EDIT_TITLE                      : "Editar red social",
    SOCIALS_DELETE_TITLE                    : "Eliminar red social",
    SOCIALS_DELETE_TEXT                     : "¿Realmente desea eliminar la red social <b>{0}</b>?",
    SOCIALS_NONE_AVAILABLE                  : "No hay redes sociales disponibles.",
    SOCIALS_ERROR_EXISTS                    : "No existe la red social indicada.",
    SOCIALS_ERROR_TYPE_EMPTY                : "Debe indicar el tipo de la red social.",
    SOCIALS_ERROR_TYPE_INVALID              : "El tipo de la red social es inválido.",
    SOCIALS_ERROR_TYPE_EXISTS               : "No se pueden tener múltiples redes sociales de un mismo tipo.",
    SOCIALS_ERROR_NAME                      : "Debe indicar el nombre de la red social.",
    SOCIALS_ERROR_URL                       : "Debe indicar la url de la red social.",
    SOCIALS_SUCCESS_CREATE                  : "La red social se creó correctamente.",
    SOCIALS_SUCCESS_EDIT                    : "La red social se editó correctamente.",
    SOCIALS_SUCCESS_DELETE                  : "La red social se eliminó correctamente.",



    // Tickets
    TICKETS_NAME                            : "Gestiones",
    TICKETS_SINGULAR                        : "Gestión",
    TICKETS_TITLE                           : "Gestión #{0}",
    TICKETS_VIEW_TITLE                      : "Ver gestión",
    TICKETS_CREATE_TITLE                    : "Crear gestión",
    TICKETS_EDIT_TITLE                      : "Editar gestión",
    TICKETS_DELETE_TITLE                    : "Eliminar gestión",
    TICKETS_DELETE_TEXT                     : "¿Realmente desea eliminar la gestión <b>#{0}</b>?",
    TICKETS_OPEN_TITLE                      : "Abrir gestión",
    TICKETS_OPEN_TEXT                       : "¿Realmente desea abrir la gestión <b>#{0}</b>?",
    TICKETS_OPEN_TAB                        : "Gestiones abiertas",
    TICKETS_WAITING_TITLE                   : "Mover a lista de espera",
    TICKETS_WAITING_TEXT                    : "Seleccione la lista de espera a la cual desea mover la gestión <b>#{0}</b>.",
    TICKETS_WAITING_TAB                     : "Gestiones esperando",
    TICKETS_CLOSE_TITLE                     : "Cerrar gestión",
    TICKETS_CLOSE_TEXT                      : "¿Realmente desea cerrar la gestión <b>#{0}</b>?",
    TICKETS_CLOSE_TAB                       : "Gestiones cerradas",
    TICKETS_ASSIGNED                        : "Asignada a",
    TICKETS_ONLY_MINE                       : "Solo mi gestión",
    TICKETS_LAST_REPLY                      : "Última respuesta",
    TICKETS_NONE_AVAILABLE                  : "No hay gestiones disponibles.",
    TICKETS_NONE_MESSAGES                   : "No hay mensajes disponibles.",
    TICKETS_WRITE_MESSAGE                   : "Escribir mensaje",
    TICKETS_EDIT_MESSAGE                    : "Editar mensaje",
    TICKETS_DELETE_MESSAGE                  : "Eliminar mensaje",
    TICKETS_DELETE_MESSAGE_TEXT             : "¿Realmente desea eliminar el mensaje <b>{0}</b>?",
    TICKETS_DELETE_FILE                     : "Eliminar archivo",
    TICKETS_DELETE_FILE_TEXT                : "¿Realmente desea eliminar el archivo de <b>{0}</b>?",
    TICKETS_NOTE                            : "Nota",
    TICKETS_WRITE_NOTE                      : "Escribe una nota",
    TICKETS_EDIT_NOTE                       : "Editar nota",
    TICKETS_DELETE_NOTE                     : "Eliminar nota",
    TICKETS_DELETE_NOTE_TEXT                : "¿Realmente desea eliminar la nota <b>{0}</b>?",
    TICKETS_ERROR_EDIT                      : "No tiene permiso para editar la gestión.",
    TICKETS_ERROR_EXISTS                    : "No existe la gestión indicada.",
    TICKETS_ERROR_AREA                      : "Debe indicar el área de la gestión.",
    TICKETS_ERROR_SUBJECT                   : "Debe indicar el tema de la gestión.",
    TICKETS_ERROR_DEPARTMENT                : "Debe indicar el departamento de la gestión.",
    TICKETS_ERROR_PRIORITY_EMPTY            : "Debe indicar la prioridad del ticket.",
    TICKETS_ERROR_PRIORITY_INVALID          : "La prioridad indicada es inválida.",
    TICKETS_ERROR_MEMBER_NUMBER             : "Debe indicar el número de socio de la gestión.",
    TICKETS_ERROR_MESSAGE                   : "Debe indicar el mensaje de la gestión.",
    TICKETS_ERROR_DELETE                    : "No tiene permiso para eliminar la gestión.",
    TICKETS_ERROR_ASSIGN_PERMISSION         : "No tiene permiso para asignar la gestión.",
    TICKETS_ERROR_ASSIGN_EXISTS             : "No existe el usuario a quien desea asignar la gestión.",
    TICKETS_ERROR_OPEN                      : "No tiene permiso para abrir la gestión.",
    TICKETS_ERROR_WAIT                      : "No tiene permiso para mover la gestión a una lista de espera.",
    TICKETS_ERROR_CLOSE                     : "No tiene permiso para cerrar la gestión.",
    TICKETS_ERROR_MESSAGE_EXISTS            : "No existe el mensaje indicado.",
    TICKETS_ERROR_MESSAGE_EDIT              : "No tiene permiso para editar el mensaje indicado.",
    TICKETS_ERROR_MESSAGE_DELETE            : "No tiene permiso para eliminar el mensaje indicado.",
    TICKETS_ERROR_DELETE_FILE               : "El mensaje indicado no tiene un archivo.",
    TICKETS_SUCCESS_CREATE                  : "La gestión se creó correctamente.",
    TICKETS_SUCCESS_EDIT                    : "La gestión se editó correctamente.",
    TICKETS_SUCCESS_DELETE                  : "La gestión se eliminó correctamente.",
    TICKETS_SUCCESS_OPEN                    : "La gestión se abrió correctamente.",
    TICKETS_SUCCESS_WAIT                    : "La gestión se movió a la lista de espera correctamente.",
    TICKETS_SUCCESS_CLOSE                   : "La gestión se cerró correctamente.",
    TICKETS_SUCCESS_ADD_MESSAGE             : "El mensaje se agregó correctamente.",
    TICKETS_SUCCESS_EDIT_MESSAGE            : "El mensaje se editó correctamente.",
    TICKETS_SUCCESS_DELETE_MESSAGE          : "El mensaje se eliminó correctamente.",
    TICKETS_SUCCESS_ADD_NOTE                : "La nota se agregó correctamente.",
    TICKETS_SUCCESS_EDIT_NOTE               : "La nota se editó correctamente.",
    TICKETS_SUCCESS_DELETE_NOTE             : "La nota se eliminó correctamente.",
    TICKETS_SUCCESS_DELETE_FILE             : "El archivo se eliminó correctamente.",

    // Tickets: Areas
    TICKET_AREAS_NAME                       : "Áreas",
    TICKET_AREAS_SINGULAR                   : "Área",
    TICKET_AREAS_CREATE_TITLE               : "Crear área",
    TICKET_AREAS_EDIT_TITLE                 : "Editar área",
    TICKET_AREAS_DELETE_TITLE               : "Eliminar área",
    TICKET_AREAS_DELETE_TEXT                : "¿Realmente desea eliminar la área <b>{0}</b>?",
    TICKET_AREAS_NONE_AVAILABLE             : "No hay áreas disponibles.",
    TICKET_AREAS_ERROR_EXISTS               : "No existe la área indicada.",
    TICKET_AREAS_ERROR_NAME                 : "Debe indicar el nombre de la área.",
    TICKET_AREAS_SUCCESS_CREATE             : "La área se creó correctamente.",
    TICKET_AREAS_SUCCESS_EDIT               : "La área se editó correctamente.",
    TICKET_AREAS_SUCCESS_DELETE             : "La área se eliminó correctamente.",

    // Tickets: Departments
    TICKET_DEPARTMENTS_NAME                 : "Departamentos",
    TICKET_DEPARTMENTS_SINGULAR             : "Departamento",
    TICKET_DEPARTMENTS_CREATE_TITLE         : "Crear departamento",
    TICKET_DEPARTMENTS_EDIT_TITLE           : "Editar departamento",
    TICKET_DEPARTMENTS_DELETE_TITLE         : "Eliminar departamento",
    TICKET_DEPARTMENTS_DELETE_TEXT          : "¿Realmente desea eliminar el departamento <b>{0}</b>?",
    TICKET_DEPARTMENTS_ASSIGN_TO            : "Asignar automáticamente a",
    TICKET_DEPARTMENTS_ASSIGNED             : "Asignado a",
    TICKET_DEPARTMENTS_NONE_AVAILABLE       : "No hay departamentos disponibles.",
    TICKET_DEPARTMENTS_ERROR_EXISTS         : "No existe el departamento indicado.",
    TICKET_DEPARTMENTS_ERROR_NAME           : "Debe indicar el nombre del departamento.",
    TICKET_DEPARTMENTS_ERROR_AREA           : "Debe indicar el área del departamento.",
    TICKET_DEPARTMENTS_ERROR_CREDENTIAL     : "Debe indicar a quien asignar automáticamente.",
    TICKET_DEPARTMENTS_SUCCESS_CREATE       : "El departamento se creó correctamente.",
    TICKET_DEPARTMENTS_SUCCESS_EDIT         : "El departamento se editó correctamente.",
    TICKET_DEPARTMENTS_SUCCESS_DELETE       : "El departamento se eliminó correctamente.",

    // Tickets: Subjects
    TICKET_SUBJECTS_NAME                    : "Temas",
    TICKET_SUBJECTS_SINGULAR                : "Tema",
    TICKET_SUBJECTS_CREATE_TITLE            : "Crear tema",
    TICKET_SUBJECTS_EDIT_TITLE              : "Editar tema",
    TICKET_SUBJECTS_DELETE_TITLE            : "Eliminar tema",
    TICKET_SUBJECTS_DELETE_TEXT             : "¿Realmente desea eliminar el tema <b>{0}</b>?",
    TICKET_SUBJECTS_NONE_AVAILABLE          : "No hay temas disponibles.",
    TICKET_SUBJECTS_ERROR_EXISTS            : "No existe el tema indicada.",
    TICKET_SUBJECTS_ERROR_NAME              : "Debe indicar el nombre del tema.",
    TICKET_SUBJECTS_ERROR_AREA              : "Debe indicar el área del tema.",
    TICKET_SUBJECTS_ERROR_DEPARTMENT        : "Debe indicar el departamento del tema.",
    TICKET_SUBJECTS_SUCCESS_CREATE          : "El tema se creó correctamente.",
    TICKET_SUBJECTS_SUCCESS_EDIT            : "El tema se editó correctamente.",
    TICKET_SUBJECTS_SUCCESS_DELETE          : "El tema se eliminó correctamente.",

    // Tickets: Waitlists
    TICKET_WAITLISTS_NAME                   : "Listas de espera",
    TICKET_WAITLISTS_SINGULAR               : "Lista de espera",
    TICKET_WAITLISTS_CREATE_TITLE           : "Crear lista de espera",
    TICKET_WAITLISTS_EDIT_TITLE             : "Editar lista de espera",
    TICKET_WAITLISTS_DELETE_TITLE           : "Eliminar lista de espera",
    TICKET_WAITLISTS_DELETE_TEXT            : "¿Realmente desea eliminar la lista de espera <b>{0}</b>?",
    TICKET_WAITLISTS_NONE_AVAILABLE         : "No hay listas de espera disponibles.",
    TICKET_WAITLISTS_ERROR_EXISTS           : "No existe la lista de espera indicada.",
    TICKET_WAITLISTS_ERROR_NAME             : "Debe indicar el nombre de la lista de espera.",
    TICKET_WAITLISTS_SUCCESS_CREATE         : "La lista de espera se creó correctamente.",
    TICKET_WAITLISTS_SUCCESS_EDIT           : "La lista de espera se editó correctamente.",
    TICKET_WAITLISTS_SUCCESS_DELETE         : "La lista de espera se eliminó correctamente.",



    // Members
    MEMBERS_NAME                            : "Socios",
    MEMBERS_FILTER_TITLE                    : "Filtrar socios",
    MEMBERS_VIEW_TITLE                      : "Ver socios",
    MEMBERS_REGISTER_TITLE                  : "Marcar como registrado",
    MEMBERS_REGISTER_TEXT                   : "¿Realmente desea marcar como registrado al socio <b>{0}</b>?",
    MEMBERS_UNREGISTER_TITLE                : "Marcar como no registrado",
    MEMBERS_UNREGISTER_TEXT                 : "¿Realmente desea marcar como no registrado al socio <b>{0}</b>?",
    MEMBERS_FATHER_NUMBER                   : "Número de socio del padre",
    MEMBERS_MOTHER_NUMBER                   : "Número de socio de la madre",
    MEMBERS_SPOUSE_NUMBER                   : "Número de socio del cónyuge",
    MEMBERS_IN_CHARGE_NUMBER                : "Número de socio a cargo",
    MEMBERS_FAMILY_GROUP                    : "Grupo familiar",
    MEMBERS_IS_REGISTERED                   : "Registrado",
    MEMBERS_REGISTERED_DATE                 : "Fecha de registro",
    MEMBERS_NONE_AVAILABLE                  : "No hay socios disponibles.",
    MEMBERS_ERROR_EXISTS                    : "No existe el socio indicado.",
    MEMBERS_ERROR_REGISTERED                : "No puede marcar como registrado al socio indicado.",
    MEMBERS_ERROR_NOT_REGISTERED            : "No puede marcar como no registrado al socio indicado.",
    MEMBERS_SUCCESS_REGISTER                : "El socio se marcó como registrado correctamente.",
    MEMBERS_SUCCESS_UNREGISTER              : "El socio se marcó como no registrado correctamente.",

    // Members: States
    MEMBERS_STATES_NAME                     : "Estados",
    MEMBERS_STATES_SINGULAR                 : "Estado",
    MEMBERS_STATES_CREATE_TITLE             : "Crear estado",
    MEMBERS_STATES_EDIT_TITLE               : "Editar estado",
    MEMBERS_STATES_DELETE_TITLE             : "Eliminar estado",
    MEMBERS_STATES_DELETE_TEXT              : "¿Realmente desea eliminar el estado <b>{0}</b>?",
    MEMBERS_STATES_ALLOWS_ACCESS            : "Permite acceso",
    MEMBERS_STATES_COUNT_SINGULAR           : "1 estado",
    MEMBERS_STATES_COUNT_PLURAL             : "{0} estados",
    MEMBERS_STATES_NONE_AVAILABLE           : "No hay estados disponibles.",
    MEMBERS_STATES_ERROR_EXISTS             : "No existe el estado indicado.",
    MEMBERS_STATES_ERROR_STATE_CODE_EMPTY   : "Debe indicar el código del estado.",
    MEMBERS_STATES_ERROR_STATE_CODE_EXISTS  : "No puede repetir los códigos de los estados.",
    MEMBERS_STATES_ERROR_NAME               : "Debe indicar el nombre del estado.",
    MEMBERS_STATES_SUCCESS_CREATE           : "El estado se creó correctamente.",
    MEMBERS_STATES_SUCCESS_EDIT             : "El estado se editó correctamente.",
    MEMBERS_STATES_SUCCESS_DELETE           : "El estado se eliminó correctamente.",

    // Members: Relations
    MEMBERS_RELATIONS_NAME                  : "Relaciones",
    MEMBERS_RELATIONS_SINGULAR              : "Relación",
    MEMBERS_RELATIONS_EDIT_TITLE            : "Editar relación",
    MEMBERS_RELATIONS_NONE_AVAILABLE        : "No hay relaciones disponibles.",
    MEMBERS_RELATIONS_ERROR_EXISTS          : "No existe la relación indicada.",
    MEMBERS_RELATIONS_ERROR_NAME            : "Debe indicar el nombre de la relación.",
    MEMBERS_RELATIONS_SUCCESS_EDIT          : "La relación se editó correctamente.",

    // Members: Provinces
    PROVINCES_NAME                          : "Provincias",
    PROVINCES_SINGULAR                      : "Provincia",
    PROVINCES_CREATE_TITLE                  : "Crear provincia",
    PROVINCES_EDIT_TITLE                    : "Editar provincia",
    PROVINCES_DELETE_TITLE                  : "Eliminar provincia",
    PROVINCES_DELETE_TEXT                   : "¿Realmente desea eliminar la provincia <b>{0}</b>?",
    PROVINCES_NONE_AVAILABLE                : "No hay provincias disponibles.",
    PROVINCES_ERROR_EXISTS                  : "No existe la provincia indicada.",
    PROVINCES_ERROR_NAME                    : "Debe completar el nombre de la provincia.",
    PROVINCES_ERROR_CODE_EMPTY              : "Debe indicar el código de la provincia.",
    PROVINCES_ERROR_CODE_UNIQUE             : "Los códigos de las provincias no se puede repetir.",
    PROVINCES_SUCCESS_CREATE                : "La provincia se creó correctamente.",
    PROVINCES_SUCCESS_EDIT                  : "La provincia se editó correctamente.",
    PROVINCES_SUCCESS_DELETE                : "La provincia se eliminó correctamente.",



    // Documentation
    DOCUMENTS_NAME                          : "Documentación",
    DOCUMENTS_SINGULAR                      : "Documento",
    DOCUMENTS_CREATE_TITLE                  : "Crear documento",
    DOCUMENTS_EDIT_TITLE                    : "Editar documento",
    DOCUMENTS_PRINT_TITLE                   : "Imprimir documento",
    DOCUMENTS_DELETE_TITLE                  : "Eliminar documento",
    DOCUMENTS_DELETE_TEXT                   : "¿Realmente desea eliminar el documento <b>{0}</b>?",
    DOCUMENTS_WARNING_TITLE                 : "Cambios sin guardar",
    DOCUMENTS_WARNING_TEXT                  : "Hay cambios sin guardar en el documento. ¿Desea descartarlos o seguir editando y guardarlos?",
    DOCUMENTS_SELECT_ONE                    : "Selecciona un documento del listado para poder obtener la información que necesita.",
    DOCUMENTS_HELP                          : "Ayuda del módulo",
    DOCUMENTS_PARENT                        : "Documento padre",
    DOCUMENTS_URL                           : "URL del módulo",
    DOCUMENTS_TYPE_MAIN                     : "Principal",
    DOCUMENTS_TYPE_PARTNER                  : "Partner",
    DOCUMENTS_TYPE_ADMINS                   : "Administrativo",
    DOCUMENTS_TYPE_RELEASES                 : "Releases",
    DOCUMENTS_NONE_AVAILABLE                : "No hay documentos disponibles.",
    DOCUMENTS_ERROR_EXISTS                  : "No existe el documento indicado.",
    DOCUMENTS_ERROR_TYPE                    : "No existe el tipo de documento indicado.",
    DOCUMENTS_ERROR_NAME_EMPTY              : "Debe indicar el nombre del documento.",
    DOCUMENTS_ERROR_NAME_REPEATED           : "No puede repetir los nombres de los documentos dentro del padre indicado.",
    DOCUMENTS_ERROR_URL                     : "La url no debe ser relativa y no incluir el dominio.",
    DOCUMENTS_ERROR_PARENT_EXISTS           : "No existe el padre indicado.",
    DOCUMENTS_ERROR_PARENT_SELF             : "No puede seleccionar el mismo documento como el padre.",
    DOCUMENTS_ERROR_PARENT_CYCLE            : "No puede crear ciclos con los documentos.",
    DOCUMENTS_SUCCESS_CREATE                : "El documento se creó correctamente.",
    DOCUMENTS_SUCCESS_EDIT                  : "El documento se editó correctamente.",
    DOCUMENTS_SUCCESS_DELETE                : "El documento se eliminó correctamente.",



    // Setup: Admins
    ADMINS_NAME                             : "Admins",
    ADMINS_CREATE_TITLE                     : "Crear admin",
    ADMINS_EDIT_TITLE                       : "Editar admin",
    ADMINS_DELETE_TITLE                     : "Eliminar admin",
    ADMINS_DELETE_TEXT                      : "¿Realmente desea eliminar el admin <b>{0}</b>?",
    ADMINS_NONE_AVAILABLE                   : "No hay admins disponibles.",
    ADMINS_ERROR_EDIT                       : "No puede editar el admin indicado.",
    ADMINS_ERROR_EXISTS                     : "No existe el admin indicado.",
    ADMINS_ERROR_ACTIVITY                   : "Debe completar la actividad del admin.",
    ADMINS_ERROR_DELETE                     : "No puede eliminar el admin indicado.",
    ADMINS_SUCCESS_CREATE                   : "El admin se creó correctamente.",
    ADMINS_SUCCESS_EDIT                     : "El admin se editó correctamente.",
    ADMINS_SUCCESS_DELETE                   : "El admin se eliminó correctamente.",

    // Setup: Versions
    VERSIONS_NAME                           : "Versiones",
    VERSIONS_SINGULAR                       : "Versión",
    VERSIONS_CREATE_TITLE                   : "Crear versión",
    VERSIONS_EDIT_TITLE                     : "Editar versión",
    VERSIONS_DELETE_TITLE                   : "Eliminar versión",
    VERSIONS_DELETE_TEXT                    : "¿Realmente desea eliminar la versión <b>{0}</b>?",
    VERSIONS_ENVIRONMENT                    : "Tipo de la versión",
    VERSIONS_NONE_AVAILABLE                 : "No hay versiones disponibles.",
    VERSIONS_ERROR_EXISTS                   : "No existe la versión indicado.",
    VERSIONS_ERROR_VERSION_EMPTY            : "Debe completar la versión.",
    VERSIONS_ERROR_VERSION_UNIQUE           : "El valor de la versión debe ser único.",
    VERSIONS_ERROR_ENVIRONMENT_EMPTY        : "Debe indicar el tipo de la versión.",
    VERSIONS_ERROR_ENVIRONMENT_INVALID      : "El tipo de la versión es incorrecto.",
    VERSIONS_SUCCESS_CREATE                 : "La versión se creó correctamente.",
    VERSIONS_SUCCESS_EDIT                   : "La versión se editó correctamente.",
    VERSIONS_SUCCESS_DELETE                 : "La versión se eliminó correctamente.",

    // Setup: Media
    MEDIA_NAME                              : "Archivos",
    MEDIA_SINGULAR                          : "Archivo",
    MEDIA_SELECT_TITLE                      : "Seleccionar archivo",
    MEDIA_CREATE_TITLE                      : "Crear directorio",
    MEDIA_EDIT_DIR_TITLE                    : "Renombrar directorio",
    MEDIA_EDIT_FILE_TITLE                   : "Renombrar archivo",
    MEDIA_DELETE_DIR_TITLE                  : "Eliminar directorio",
    MEDIA_DELETE_FILE_TITLE                 : "Eliminar archivo",
    MEDIA_DELETE_DIR_TEXT                   : "¿Realmente desea eliminar el directorio <b>{0}</b>?",
    MEDIA_DELETE_FILE_TEXT                  : "¿Realmente desea eliminar el archivo <b>{0}</b>?",
    MEDIA_NONE_AVAILABLE                    : "No hay archivos disponibles.",
    MEDIA_ERROR_CREATE                      : "No fue posible crear el directorio indicado.",
    MEDIA_ERROR_CREATE_NAME                 : "Debe indicar el nombre del directorio.",
    MEDIA_ERROR_CREATE_EXISTS               : "Ya existe un archivo/directorio con ese nombre.",
    MEDIA_ERROR_UPLOAD                      : "Ocurrió un error al subir el archivo.",
    MEDIA_ERROR_UPLOAD_EMPTY                : "Debe indicar un archivo para subir.",
    MEDIA_ERROR_UPLOAD_SIZE                 : "El archivo pesa más de 3mb.",
    MEDIA_ERROR_UPLOAD_SIZES                : "Hay archivos que pesan más de 3mb.",
    MEDIA_ERROR_UPLOAD_EXISTS               : "Ya existe un archivo con ese nombre.",
    MEDIA_ERROR_RENAME                      : "Ocurrió un error al renombrar el archivo/directorio indicado.",
    MEDIA_ERROR_RENAME_OLD_NAME             : "Debe indicar el archivo/directorio para renombrar.",
    MEDIA_ERROR_RENAME_NEW_NAME             : "Debe indicar el nuevo nombre del archivo.",
    MEDIA_ERROR_RENAME_OLD_EXISTS           : "No existe el archivo/directorio para renombrar.",
    MEDIA_ERROR_RENAME_NEW_EXISTS           : "Ya existe un archivo/directorio con el nuevo nombre.",
    MEDIA_ERROR_MOVE                        : "Ocurrió un error al mover el archivo indicado.",
    MEDIA_ERROR_MOVE_OLD_PATH               : "Debe indicar el archivo para mover.",
    MEDIA_ERROR_MOVE_NEW_PATH               : "Debe indicar el nuevo directorio del archivo.",
    MEDIA_ERROR_MOVE_OLD_EXISTS             : "No existe el archivo para mover.",
    MEDIA_ERROR_MOVE_NEW_EXISTS             : "Ya existe un archivo con el mismo nombre en el directorio indicado.",
    MEDIA_ERROR_DELETE                      : "Ocurrió un error al eliminar el archivo/directorio indicado.",
    MEDIA_ERROR_DELETE_NAME                 : "Debe indicar el archivo/directorio para eliminar.",
    MEDIA_ERROR_DELETE_EXISTS               : "No existe el archivo/directorio para eliminar.",
    MEDIA_SUCCESS_CREATE                    : "El directorio se creó correctamente.",
    MEDIA_SUCCESS_UPLOAD                    : "El archivo se subió correctamente.",
    MEDIA_SUCCESS_RENAME                    : "El archivo/directorio se renombró correctamente.",
    MEDIA_SUCCESS_MOVE                      : "El archivo se movió correctamente.",
    MEDIA_SUCCESS_DELETE                    : "El archivo/directorio se eliminó correctamente.",

    // Setup: Notification Types
    NOTIFICATION_TYPES_NAME                 : "Tipos de notificaciones",
    NOTIFICATION_TYPES_SINGULAR             : "Tipo de notificación",
    NOTIFICATION_TYPES_CREATE_TITLE         : "Crear tipo de notificación",
    NOTIFICATION_TYPES_EDIT_TITLE           : "Editar tipo de notificación",
    NOTIFICATION_TYPES_DELETE_TITLE         : "Eliminar tipo de notificación",
    NOTIFICATION_TYPES_DELETE_TEXT          : "¿Realmente desea eliminar la tipo de notificación <b>{0}</b>?",
    NOTIFICATION_TYPES_ADMINISTRATION       : "Administración",
    NOTIFICATION_TYPES_FOR_ACTIVITY         : "Para actividad",
    NOTIFICATION_TYPES_NONE_AVAILABLE       : "No hay tipos de notificaciones disponibles.",
    NOTIFICATION_TYPES_ERROR_EXISTS         : "No existe la tipo de notificación indicada.",
    NOTIFICATION_TYPES_ERROR_ACTIVITY       : "Debe completar la actividad del tipo de notificación.",
    NOTIFICATION_TYPES_ERROR_NAME           : "Debe completar el nombre del tipo de notificación.",
    NOTIFICATION_TYPES_SUCCESS_CREATE       : "El tipo de notificación se creó correctamente.",
    NOTIFICATION_TYPES_SUCCESS_EDIT         : "El tipo de notificación se editó correctamente.",
    NOTIFICATION_TYPES_SUCCESS_DELETE       : "El tipo de notificación se eliminó correctamente.",

    // Setup: Notifications
    NOTIFICATIONS_NAME                      : "Notificaciones",
    NOTIFICATIONS_SINGULAR                  : "Notificación",
    NOTIFICATIONS_VIEW_TITLE                : "Ver notificación",
    NOTIFICATIONS_EXTERNAL_ID               : "ID del proveedor",
    NOTIFICATIONS_NONE_AVAILABLE            : "No hay notificaciones disponibles.",



    // Email: Templates
    EMAIL_TEMPLATES_NAME                    : "Plantillas de emails",
    EMAIL_TEMPLATES_VIEW_TITLE              : "Ver plantilla de email",
    EMAIL_TEMPLATES_NONE_AVAILABLE          : "No hay plantillas de emails disponibles.",

    // Email: Queue
    EMAIL_QUEUE_NAME                        : "Cola de emails",
    EMAIL_QUEUE_VIEW_TITLE                  : "Ver email",
    EMAIL_QUEUE_RESEND_TITLE                : "Reenviar email",
    EMAIL_QUEUE_RESEND_TEXT_SINGULAR        : "¿Realmente desea marcar <b>{0}</b> email para envío?",
    EMAIL_QUEUE_RESEND_TEXT_PLURAL          : "¿Realmente desea marcar <b>{0}</b> emails para envío?",
    EMAIL_QUEUE_NONE_AVAILABLE              : "No hay emails disponibles en la cola de emails.",
    EMAIL_QUEUE_ERROR_RESEND                : "Debe indicar al menos un email a para reenviar.",
    EMAIL_QUEUE_SUCCESS_RESEND              : "El email se marcó para envió correctamente.",
    EMAIL_QUEUE_SUCCESS_MULTI_RESEND        : "Los emails se marcaron para envío correctamente.",

    // Email: White List
    EMAIL_WHITE_LIST_NAME                   : "Lista blanca de emails",
    EMAIL_WHITE_LIST_CREATE_TITLE           : "Agregar email",
    EMAIL_WHITE_LIST_EDIT_TITLE             : "Editar email",
    EMAIL_WHITE_LIST_DELETE_TITLE           : "Quitar email",
    EMAIL_WHITE_LIST_DELETE_TEXT            : "¿Realmente desea quitar el email <b>{0}</b>?",
    EMAIL_WHITE_LIST_EMAIL                  : "Email disponible para envíos",
    EMAIL_WHITE_LIST_DESCRIPTION            : "Descripción sobre el email",
    EMAIL_WHITE_LIST_NONE_AVAILABLE         : "No hay emails disponibles.",
    EMAIL_WHITE_LIST_ERROR_EXISTS           : "No existe la plantilla de email indicada.",
    EMAIL_WHITE_LIST_ERROR_EMPTY            : "Debe indicar el email a agregar.",
    EMAIL_WHITE_LIST_ERROR_INVALID          : "El email indicado es incorrecto.",
    EMAIL_WHITE_LIST_ERROR_USED             : "El email indicado ya esta agregado.",
    EMAIL_WHITE_LIST_SUCCESS_ADD            : "El email se agregó correctamente.",
    EMAIL_WHITE_LIST_SUCCESS_EDIT           : "El email se editó correctamente.",
    EMAIL_WHITE_LIST_SUCCESS_REMOVE         : "El email se quitó correctamente.",



    // Log: Providers
    PROVIDERS_LOG_NAME                      : "Log de proveedores",
    PROVIDERS_LOG_VIEW_TITLE                : "Ver log de proveedor",
    PROVIDERS_LOG_PROVIDER                  : "Proveedor",
    PROVIDERS_LOG_ACTION                    : "Acción",
    PROVIDERS_LOG_METHOD                    : "Método",
    PROVIDERS_LOG_URL                       : "Url",
    PROVIDERS_LOG_REQUEST                   : "Request",
    PROVIDERS_LOG_RESPONSE                  : "Respuesta",
    PROVIDERS_LOG_NONE_AVAILABLE            : "No hay logs de proveedores disponibles.",

    // Log: Actions
    ACTIONS_LOG_NAME                        : "Log de acciones",
    ACTIONS_LOG_SESSION                     : "Sesión",
    ACTIONS_LOG_MODULE                      : "Módulo",
    ACTIONS_LOG_ACTION                      : "Acción",
    ACTIONS_LOG_NONE_AVAILABLE              : "No hay acciones disponibles.",

    // Log: Errors
    ERRORS_LOG_NAME                         : "Log de errores",
    ERRORS_LOG_VIEW_TITLE                   : "Ver error",
    ERRORS_LOG_RESOLVE_TITLE                : "Marcar como resuelto",
    ERRORS_LOG_RESOLVE_TEXT_SINGULAR        : "¿Realmente desea marcar <b>{0}</b> error como resuelto?",
    ERRORS_LOG_RESOLVE_TEXT_PLURAL          : "¿Realmente desea marcar <b>{0}</b> errores como resueltos?",
    ERRORS_LOG_DELETE_TITLE                 : "Eliminar error",
    ERRORS_LOG_DELETE_TEXT_SINGULAR         : "¿Realmente desea eliminar <b>{0}</b> error?",
    ERRORS_LOG_DELETE_TEXT_PLURAL           : "¿Realmente desea eliminar <b>{0}</b> errores?",
    ERRORS_LOG_AMOUNT                       : "Cantidad",
    ERRORS_LOG_ENVIRONMENT                  : "Entorno",
    ERRORS_LOG_FILE                         : "Archivo",
    ERRORS_LOG_BACKTRACE                    : "Backtrace",
    ERRORS_LOG_RESOLVED                     : "Resuelto",
    ERRORS_LOG_NONE_AVAILABLE               : "No hay errores disponibles.",
    ERRORS_LOG_ERROR_RESOLVE                : "Debe indicar al menos un error a para resolver.",
    ERRORS_LOG_ERROR_DELETE                 : "Debe indicar al menos un error a eliminar.",
    ERRORS_LOG_SUCCESS_RESOLVED             : "El error se marcó como resuelto correctamente.",
    ERRORS_LOG_SUCCESS_MULTI_RESOLVED       : "Los errores se marcaron como resueltos correctamente.",
    ERRORS_LOG_SUCCESS_DELETE               : "El error se eliminó correctamente.",
    ERRORS_LOG_SUCCESS_MULTI_DELETE         : "Los errores se eliminaron correctamente.",



    // DropZone
    DROPZONE_FILES_TITLE                    : "Arrastra archivos a cualquier lugar para subirlos",
    DROPZONE_IMAGES_TITLE                   : "Arrastra imágenes a cualquier lugar para subirlas",
    DROPZONE_OR                             : "ó",
    DROPZONE_FILES_BUTTON                   : "Seleccionar archivos",
    DROPZONE_IMAGES_BUTTON                  : "Seleccionar imágenes",
    DROPZONE_FILES_DROP                     : "Arrastra los archivos aquí para subirlos",
    DROPZONE_IMAGES_DROP                    : "Arrastra las imágenes aquí para subirlas",

    // Periods
    PERIOD_NAME                             : "Periodo",
    PERIOD_SELECT                           : "Seleccionar periodo",
    PERIOD_TODAY                            : "Hoy",
    PERIOD_YESTERDAY                        : "Ayer",
    PERIOD_LAST_7_DAYS                      : "Últimos 7 días",
    PERIOD_LAST_15_DAYS                     : "Últimos 15 días",
    PERIOD_LAST_30_DAYS                     : "Últimos 30 días",
    PERIOD_LAST_60_DAYS                     : "Últimos 60 días",
    PERIOD_LAST_90_DAYS                     : "Últimos 90 días",
    PERIOD_LAST_120_DAYS                    : "Últimos 120 días",
    PERIOD_LAST_YEAR                        : "Último año",
    PERIOD_THIS_WEEK                        : "Esta semana",
    PERIOD_THIS_MONTH                       : "Este mes",
    PERIOD_THIS_YEAR                        : "Este año",
    PERIOD_PAST_WEEK                        : "La semana pasada",
    PERIOD_PAST_MONTH                       : "El mes pasado",
    PERIOD_PAST_YEAR                        : "El año pasado",
    PERIOD_ALL_PERIOD                       : "Todo el periodo",
    PERIOD_CUSTOM                           : "Personalizado",

    // Replaceable Date Formats. {d: Day number, d0: 2 digit day, dn: Day name, d3: 3 letters day name, m: month, m0: 2 digit month, mn: Month name, m3: 3 letters month name, y: year, h: hours, i: minutes}
    DATE_DAY_NAMES                          : [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado" ],
    DATE_DAY_NAMES_MONDAY                   : { 1 : "Lunes", 2 : "Martes", 3 : "Miércoles", 4 : "Jueves", 5 : "Viernes", 6 : "Sábado", 7 : "Domingo" },
    DATE_DAY_HOLIDAY_NAMES                  : [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Feriados" ],
    DATE_DAY_HOLIDAY_NAMES_MONDAY           : { 1 : "Lunes", 2 : "Martes", 3 : "Miércoles", 4 : "Jueves", 5 : "Viernes", 6 : "Sábado", 7 : "Domingo", 8 : "Feriados" },
    DATE_DAY_SHORTS                         : [ "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá" ],
    DATE_DAY_POSITIONS                      : [ "Primer", "Segundo", "Tercer", "Cuarto" ],
    DATE_MONTH_NAMES                        : [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ],

    DATE_TIME                               : "{h}:{i}",
    DATE_DAY_TIME                           : "{d} {m3} {h}:{i}",
    DATE_DAY_MONTH                          : "{d} de {mn}",
    DATE_DAY_MONTH_SHORT                    : "{d}, {m3}",
    DATE_DAY_MONTH_MEDIUM                   : "{d} de {m3}",
    DATE_DAY_MONTH_YEAR                     : "{d} de {mn}, {y}",
    DATE_DAY_YEAR_SHORT                     : "{d}, {m3} {y}",
    DATE_DAY_YEAR_MEDIUM                    : "{d} de {m3} {y}",
    DATE_MONTH_YEAR                         : "{mn} {y}",
    DATE_REDUCED                            : "{dn} {d0}/{m0}",
    DATE_COMPLETE                           : "{dn}, {d} de {mn}, {y}",
    DATE_COMPLETE_SHORT                     : "{d3}, {d} de {m3}, {y}",
    DATE_COMPLETE_MONTH                     : "{dn}, {d} de {mn}",
    DATE_DASHES                             : "{d0}-{m0}-{y}",
    DATE_DASHES_REVERSE                     : "{y}-{m0}-{d0}",
    DATE_DASHES_TIME                        : "{d0}-{m0}-{y} a las {h}:{i}",
    DATE_DASHES_HOUR                        : "{d0}-{m0}-{y}, {h}:{i}",
    DATE_DASHES_SECONDS                     : "{d0}-{m0}-{y}, {h}:{i}:{s}",
    DATE_SLASHES                            : "{d0}/{m0}/{y}",
    DATE_SLASHES_DAY                        : "{d0}/{m0}",
    DATE_SORTABLE                           : "{y}/{m0}/{d0}",

    DATE_NOW                                : "Ahora",
    DATE_MIN_AGO                            : "{i} minuto atrás",
    DATE_MINS_AGO                           : "{i} minutos atrás",
    DATE_HOUR_AGO                           : "{h} hora atrás",
    DATE_HOURS_AGO                          : "{h} horas atrás",
    DATE_DAY_AGO                            : "{d} día atrás",
    DATE_DAYS_AGO                           : "{d} días atrás",
    DATE_IN_MIN                             : "En {i} minuto",
    DATE_IN_MINS                            : "En {i} minutos",
    DATE_IN_HOUR                            : "En {h} hora",
    DATE_IN_HOURS                           : "En {h} horas",
    DATE_IN_DAY                             : "En {d} día",
    DATE_IN_DAYS                            : "En {d} días",
    DATE_TOMORROW_AT                        : "Mañana a las {h}:{i}",
    DATE_TODAY_AT                           : "Hoy a las {h}:{i}",
    DATE_YESTERDAY_AT                       : "Ayer a las {h}:{i}",
    DATE_THIS_WEEK_AT                       : "{dn} a las {h}:{i}",
    DATE_THIS_YEAR_AT                       : "{d} de {m3} a las {h}:{i}",
    DATE_OTHER_YEAR_AT                      : "{d} de {m3} {y} a las {h}:{i}",

    DATE_YESTERDAY                          : "Ayer",
    DATE_TODAY                              : "Hoy",
    DATE_TOMORROW                           : "Mañana",
    DATE_LAST_WEEK                          : "La Semana Pasada",
    DATE_THIS_WEEK                          : "Esta Semana",
    DATE_NEXT_WEEK                          : "La Semana Próxima",
    DATE_PARSED_WEEK                        : "Semana del {0} al {1}",
    DATE_DURATION                           : "{0} - {1}",

};

export default strings;
