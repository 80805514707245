import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";



/**
 * Returns the Activity ID
 * @returns {Number}
 */
function useActivityID() {
    const { activityID } = Navigate.useParams();
    const { credential } = Store.useState("auth");
    return activityID || credential.activityID || 0;
}

/**
 * Goes to an Activity Url
 * @returns {Function}
 */
function useActivityGoto() {
    const { activityID } = Navigate.useParams();
    const navigate = Navigate.useGoto();

    return (...args) => {
        if (args.length === 1 && args[0].startsWith("/")) {
            navigate(...args);
        } else if (activityID) {
            navigate("ACTIVITIES", activityID, ...args);
        } else {
            navigate("ACTIVITY", ...args);
        }
    };
}




// The public API
export default {
    useActivityID,
    useActivityGoto,
};
