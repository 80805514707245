import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Activity }         from "Utils/API";



// The initial State
const initialState = {
    loading      : true,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canFilter    : false,
    canImport    : false,
    canExport    : false,
    list         : [],
    total        : 0,
    elem         : {},
    reserveTypes : [],
    sort         : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "ACTIVITY_LOADING" });
    },

    /**
     * Fetches the Activity List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await Activity.getAll(params);
        data.sort  = params;
        dispatch({ type : "ACTIVITY_LIST", data });
    },

    /**
     * Fetches a single Activity
     * @param {Function} dispatch
     * @param {Number}   activityID
     * @returns {Promise}
     */
    async fetchElem(dispatch, activityID) {
        const data = await Activity.getOne({ activityID });
        dispatch({ type : "ACTIVITY_ELEM", data });
    },

    /**
     * Edits/Creates a Activity
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return Activity.edit(data);
    },

    /**
     * Deletes a Activity
     * @param {Function} dispatch
     * @param {Number}   activityID
     * @returns {Promise}
     */
    deleteElem(dispatch, activityID) {
        return Activity.delete({ activityID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.showInAppText = Utils.toYesNo(elem.showInApp);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "ACTIVITY_LIST", "ACTIVITY_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "ACTIVITY_LOADING":
        return {
            ...state,
            loading      : true,
        };

    case "ACTIVITY_LIST":
        return {
            ...state,
            loading      : false,
            error        : false,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canFilter    : action.data.canFilter,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            list         : Utils.parseList(action.data.list, parseElem),
            total        : action.data.total,
            sort         : action.data.sort,
        };

    case "ACTIVITY_ELEM":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            elem         : parseElem(action.data.elem),
            reserveTypes : action.data.reserveTypes,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
