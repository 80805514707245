import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { Ticket }           from "Utils/API";



// The initial State
const initialState = {
    loading     : true,
    charging    : true,
    error       : false,
    edition     : 0,
    canCreate   : false,
    canEdit     : false,
    canDelete   : false,
    canFilter   : false,
    canImport   : false,
    canExport   : false,
    list        : [],
    total       : 0,
    elem        : {},
    messages    : [],
    canModify   : false,
    canOpen     : false,
    canClose    : false,
    canAssign   : false,
    canNote     : false,
    waitlists   : [],
    areas       : [],
    departments : {},
    subjects    : {},
    credentials : [],
    filters     : {},
    hasFilters  : false,
    sort        : {
        filter   : "Open",
        orderBy  : "lastReplyTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "TICKET_LOADING" });
    },

    /**
     * Fetches the Ticket List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        const data   = await Ticket.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "TICKET_LIST", data });
    },

    /**
     * Fetches a single Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @param {Boolean=} withCharging
     * @returns {Promise}
     */
    async fetchElem(dispatch, ticketID, withCharging = false) {
        if (withCharging) {
            dispatch({ type : "TICKET_CHARGING" });
        }
        const data = await Ticket.getOne({ ticketID });
        dispatch({ type : "TICKET_ELEM", data });
    },

    /**
     * Fetches the Ticket Edit data
     * @param {Function} dispatch
     * @param {Boolean=} isInitial
     * @returns {Promise}
     */
    async fetchEditData(dispatch, isInitial = true) {
        const data = await Ticket.getEditData();
        data.isInitial = isInitial;
        dispatch({ type : "TICKET_EDIT", data });
    },

    /**
     * Creates a Ticket
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    createTicket(dispatch, data) {
        return Ticket.create(data);
    },

    /**
     * Edits a Ticket
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editTicket(dispatch, data) {
        return Ticket.edit(data);
    },

    /**
     * Deletes a Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @returns {Promise}
     */
    deleteTicket(dispatch, ticketID) {
        return Ticket.delete({ ticketID });
    },

    /**
     * Opens a Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @returns {Promise}
     */
    openTicket(dispatch, ticketID) {
        return Ticket.open({ ticketID });
    },

    /**
     * Waits a Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @param {Number}   ticketWaitlistID
     * @returns {Promise}
     */
    waitTicket(dispatch, ticketID, ticketWaitlistID) {
        return Ticket.wait({ ticketID, ticketWaitlistID });
    },

    /**
     * Closes a Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @returns {Promise}
     */
    closeTicket(dispatch, ticketID) {
        return Ticket.close({ ticketID });
    },

    /**
     * Adds a Message to the Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @param {Object}   params
     * @returns {Promise}
     */
    addMessage(dispatch, ticketID, params) {
        return Ticket.addMessage({ ...params, ticketID });
    },

    /**
     * Edits a Message in the Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @param {Number}   ticketMessageID
     * @param {String}   message
     * @returns {Promise}
     */
    editMessage(dispatch, ticketID, ticketMessageID, message) {
        return Ticket.editMessage({ ticketID, ticketMessageID, message });
    },

    /**
     * Deletes a Message from the Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @param {Number}   ticketMessageID
     * @returns {Promise}
     */
    deleteMessage(dispatch, ticketID, ticketMessageID) {
        return Ticket.deleteMessage({ ticketID, ticketMessageID });
    },

    /**
     * Deletes an File from the Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @param {Number}   ticketMessageID
     * @returns {Promise}
     */
    deleteFile(dispatch, ticketID, ticketMessageID) {
        return Ticket.deleteFile({ ticketID, ticketMessageID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.lastReplyText   = DateTime.formatString(elem.lastReplyTime);
    elem.title           = NLS.format("TICKETS_TITLE", elem.ticketID);
    elem.ticketNumber    = `#${elem.ticketID}`;
    elem.priorityName    = NLS.get("SELECT_PRIORITIES", elem.priority);
    return elem;
}

/**
 * Parses all the Messages
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseMessages(list) {
    for (const elem of list) {
        elem.createdDate   = DateTime.formatDate(elem.createdTime, "dashes");
        elem.createdString = DateTime.formatString(elem.createdTime);
    }
    return list;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "TICKET_LIST", "TICKET_ELEM", "TICKET_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "TICKET_LOADING":
        return {
            ...state,
            loading     : true,
        };
    case "TICKET_CHARGING":
        return {
            ...state,
            charging    : true,
        };

    case "TICKET_LIST":
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canDelete   : action.data.canDelete,
            canFilter   : action.data.canFilter,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            list        : Utils.parseList(action.data.list, parseElem),
            total       : action.data.total,
            waitlists   : action.data.waitlists,
            filters     : action.data.filters,
            hasFilters  : !Utils.isEmpty(action.data.filters),
            sort        : action.data.sort,
        };

    case "TICKET_ELEM":
        return {
            ...state,
            charging    : false,
            error       : false,
            edition     : state.edition + 1,
            elem        : parseElem(action.data.elem),
            messages    : parseMessages(action.data.messages),
            member      : action.data.member,
            canModify   : action.data.canModify,
            canOpen     : action.data.canOpen,
            canClose    : action.data.canClose,
            canAssign   : action.data.canAssign,
            canNote     : action.data.canNote,
            areas       : action.data.areas,
            departments : action.data.departments,
            subjects    : action.data.subjects,
            credentials : action.data.credentials,
        };

    case "TICKET_EDIT":
        return {
            ...state,
            error       : false,
            edition     : state.edition + (action.data.isInitial ? 1 : 0),
            canAssign   : action.data.canAssign,
            areas       : action.data.areas,
            departments : action.data.departments,
            subjects    : action.data.subjects,
            credentials : action.data.credentials,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
