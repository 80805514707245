import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Commons              from "Utils/Commons";

// Components
import CourseEdit           from "./CourseEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Filter               from "Dashboard/Components/Filter/Filter";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Course List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CourseList(props) {
    const { type } = props;
    const { fetch, load, loadFilter } = useList("course", type);

    const activityID = Commons.useActivityID();

    const data = Store.useState("course");
    const { loading, canEdit, list, total, statuses, filters, sort } = data;

    const { deleteElem } = Store.useAction("course");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "courseID", elemID, "name");

    return <Main>
        <Header message="COURSES_NAME" icon="course">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Filter
                onFilter={loadFilter}
                values={filters}
                hasSearch
            >
                <FilterItem
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                />
            </Filter>

            <Table
                fetch={load}
                sort={sort}
                none="COURSES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"               message="GENERAL_NAME"           isTitle bigMobile />
                    <TableHeader field="availableFromTime"  message="GENERAL_AVAILABLE_FROM" maxWidth="160" />
                    <TableHeader field="availableToTime"    message="GENERAL_AVAILABLE_TO"   maxWidth="160" />
                    <TableHeader field="fromTime"           message="GENERAL_FROM"           maxWidth="100" />
                    <TableHeader field="toTime"             message="GENERAL_TO"             maxWidth="100" />
                    <TableHeader field="totalQuestions"     message="COURSES_QUESTIONS"      maxWidth="100" align="center" />
                    <TableHeader field="totalSubscriptions" message="SUBSCRIPTIONS_NAME"     maxWidth="100" align="center" />
                    <TableHeader field="status"             message="GENERAL_STATUS"         maxWidth="100" align="center" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.courseID} elemID={elem.courseID}>
                        <TableCell message={elem.name}                  />
                        <TableCell message={elem.availableFromDateTime} />
                        <TableCell message={elem.availableToDateTime}   />
                        <TableCell message={elem.fromDateTime}          />
                        <TableCell message={elem.toDateTime}            />
                        <TableCell message={elem.totalQuestions}        />
                        <TableCell message={elem.totalSubscriptions}    />
                        <TableCell message={elem.statusName}            textColor={elem.statusColor} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message="COURSES_VIEW_TITLE"   navigate />
                    <TableAction action="EDIT"   message="COURSES_EDIT_TITLE"   />
                    <TableAction action="COPY"   message="COURSES_COPY_TITLE"   />
                    <TableAction action="DELETE" message="COURSES_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <CourseEdit
            open={action.isVCCE}
            isCopy={action.isCopy}
            elemID={elemID}
            activityID={activityID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="COURSES_DELETE_TITLE"
            message="COURSES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CourseList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default CourseList;
