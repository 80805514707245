/**
 * The Brightness Variants
 * @constant {Object}
 */
export const Brightness = {
    PRIMARY : "primary",
    LIGHT   : "light",
    DARK    : "dark",
    DARKER  : "darker",
    WHITE   : "white",
    BLACK   : "black",
    ACCENT  : "accent",
    ERROR   : "error",
};

/**
 * The Semaphore Variants
 * @constant {Object}
 */
export const Semaphore = {
    GREEN  : "green",
    YELLOW : "yellow",
    RED    : "red",
    GRAY   : "gray",
    BLUE   : "blue",
};

/**
 * The Outcome Variants
 * @constant {Object}
 */
export const Outcome = {
    SUCCESS : "success",
    WARNING : "warning",
    ERROR   : "error",
};
