import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import ActivityContainer    from "Components/App/Activity/ActivityContainer";
import ReserveTypeContainer from "Components/App/Reserve/ReserveTypeContainer";
import TicketList           from "Components/App/Ticket/Ticket/TicketList";
import ProfileContainer     from "Components/App/Profile/ProfileContainer";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";



/**
 * The Support Admin Router
 * @returns {React.ReactElement}
 */
function SupportAdminRouter() {
    const { isSupportAdmin } = Store.useState("auth");


    // Do the Render
    if (!isSupportAdmin) {
        return <React.Fragment />;
    }
    return <Router type="ADMIN">
        <AdminRoute
            url="ACTIVITY"
            component={ActivityContainer}
        />
        <AdminRoute
            url="ACTIVITY_RESERVE"
            component={ReserveTypeContainer}
        />
        <AdminRoute
            url="TICKETS"
            component={TicketList}
        />

        <AdminRoute
            url="PROFILE"
            component={ProfileContainer}
        />
    </Router>;
}

export default SupportAdminRouter;
